import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { serializeBusinessError } from '../../../api/common/fetched';
import { syncTimesheetsWithQuickbooksMutation } from '../queries/syncTimesheetsWithQuickbooksMutation';
import { QuickBooksSyncTimesheetsInput } from '../models/QuickBooksSyncTimesheetsInput';
import { QuickBooksSyncDay } from '../models/QuickBooksSyncDay';

export const syncTimesheetsWithQuickbooks = createAsyncThunk<QuickBooksSyncDay, QuickBooksSyncTimesheetsInput, AppThunkConfig>(
  'quickbooks/syncTimesheetsWithQuickbooks',
  async input => {
    const result = await syncTimesheetsWithQuickbooksMutation(input.day);
    return result;
  },
  {
    serializeError: serializeBusinessError,
  }
);

import { getReportsGraphQlClient } from '../../../api/clients/getReportsGraphQlClient';
import { ZReportDateRange } from '../models/zReportDateRange';

export async function foodBevZReportsQuery(month: string | null, year: string | null): Promise<ZReportDateRange[]> {
  const result = await getReportsGraphQlClient()
    .query<{ getFoodBevZReportsDates: ZReportDateRange[] }>(
      'query($month: String, $year: String) { getFoodBevZReportsDates(month: $month, year: $year) { id, fromDate, toDate, createdDate } }',
      { month, year }
    );

  return result.getFoodBevZReportsDates;
}

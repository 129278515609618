import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksSyncDay } from '../models/QuickBooksSyncDay';
import { QuickBooksSyncDaysArgs } from '../models/QuickBooksSyncDaysArgs';

export async function getQuickBooksSyncDaysQuery(
  args: QuickBooksSyncDaysArgs
): Promise<QuickBooksSyncDay[]> {
  const result = await getQuickBooksGraphQlClient().query<{
    quickBooksSyncDays: QuickBooksSyncDay[];
  }>(
    `
      query (
         $dateFrom: String, $dateTo: String, $syncArea: QuickBooksSyncAreaEnum,
         $syncStatus: QuickBooksSyncStatusEnum, $skip: Int, $take: Int
        ) {
        quickBooksSyncDays (
           dateFrom: $dateFrom, dateTo: $dateTo, syncArea: $syncArea, syncStatus: $syncStatus, skip: $skip, take: $take
        ) {
          id
          day
          syncArea
          syncStatus
        }
      }
    `,
    {
      ...args,
    }
  );
  return result.quickBooksSyncDays;
}

export enum FailureReason {
  BusinessError = 'BusinessError',
  CommunicationError = 'CommunicationError',
  UnknownError = 'UnknownError',
}

export enum FailureCode {
  AccessCodeInvalid = 'AccessCodeInvalid',
  ActivityTypeNotFound = 'ActivityTypeNotFound',
  KioskLimitExceeded = 'KioskLimitExceeded',
  MemberAlreadyExists = 'ContactAlreadyExists',
  MemberLimitExceeded = 'ContactLimitExceeded',
  MemberNotFound = 'ContactNotFound',
  MultipleOrgsFound = 'MultipleOrgsFound',
  OperationNotAllowed = 'OperationNotAllowed',
  OrgNotFound = 'OrgNotFound',
  PromoAlreadyClaimed = 'PromoAlreadyClaimed',
  PromoNotFound = 'PromoNotFound',
  RewardAlreadyClaimed = 'RewardAlreadyClaimed',
  RewardNotFound = 'RewardNotFound',
  SmsShortCodeInvalid = 'SmsShortCodeInvalid',
  StripeUnavailable = 'StripeUnavailable',
  StripeError = 'StripeError'
}

export interface FailureData<TReason = FailureReason> {
  reason: TReason;
  code: string | FailureCode;
  // tslint:disable-next-line:no-any
  additionalData?: any;
}

import { VenueConfig } from '../models/venueConfig';
import { ConfigItem } from '../models/configItem';
import _ from 'lodash';

function setWithTypeConversion(result: VenueConfig, entry: ConfigItem) {
  const currentValue = _.get(result, entry.key);
  const valueType = typeof currentValue;
  switch (valueType) {
    case 'boolean':
      _.set(result, entry.key, entry.value === 'true');
      break;
    case 'number':
      _.set(result, entry.key, Number(entry.value));
      break;
    // when current value = null then string
    default:
      _.set(result, entry.key, entry.value as string);
      break;
  }
}

export const mapConfigEntryToVenueConfig = (configEntries: ConfigItem[]): VenueConfig => {
  const result: VenueConfig = {
    foodBev: {
      available: false,
      enabled: false,
      mode: 'notAvailable',
    },
    timezone: null,
    ptabs: {
      expenses: {
        enabled: true,
        reportingPeriodStart: null,
      },
    },
  };

  configEntries.forEach(entry => {
    if (_.has(result, entry.key)) {
      setWithTypeConversion(result, entry);
    }
  });

  return result;
};

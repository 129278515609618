import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksSyncDay } from '../models/QuickBooksSyncDay';

export async function syncSalesWithQuickbooksMutation(day: string): Promise<QuickBooksSyncDay> {
  const result = await getQuickBooksGraphQlClient().query<{
    syncSalesWithQuickbooks: QuickBooksSyncDay;
  }>(
    `
      mutation($day: String!){
        syncSalesWithQuickbooks(day: $day) {
          id
          day
          syncArea
          syncStatus
        }
      }
    `,
    { day }
  );
  return result.syncSalesWithQuickbooks;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { serializeBusinessError } from '../../../api/common/fetched';
import { getQuickBooksSyncLogsQuery } from '../queries/getQuickBooksSyncLogsQuery';
import { QuickBooksSyncLog } from '../models/QuickBooksSyncLog';

export const getQuickBooksSyncLogs = createAsyncThunk<QuickBooksSyncLog[], string, AppThunkConfig>(
  'quickbooks/getQuickBooksSyncLogs',
  async quickbooksSyncDayId => await getQuickBooksSyncLogsQuery(quickbooksSyncDayId), { serializeError: serializeBusinessError }
);

import { CampaignType } from '../../common/models/campainType';
import { CampaignStatus } from '../../common/models/campaignStatus';

export type AutopilotDetails = {
  id: string;
  campaign: CampaignType;
  expiresIn: number;
  isActive: boolean;
  ruleCompiled: string;
  ruleTemplate: string;
  ruleParams: { key: string; value: {} }[];
  ruleDefaultParams: { key: string; value: {} }[];
  ruleAllowedParams: { key: string; value: [] }[];
  customerContent: string;
  status: CampaignStatus;
  note?: string;
};

export const autopromoNotes = {
  firstVisit: 'Only applies to first visits that take place after campaign activation.',
  network: 'Always calculated since registration, regardless of campaign activation time. If the set number of days has already elapsed before the campaign is activated, a message won\'t be sent.',
  growth: 'The number of point earnings is counted from the time of campaign activation, regardless of points earned before.',
  atRisk: 'Calculated since the last visit if it takes place after the campaign is activated. Otherwise, calculated since campaign activation time.',
  lapsed: 'Calculated since the last visit if it takes place after the campaign is activated. Otherwise, calculated since campaign activation time.',
  lost: 'Calculated since the last visit if it takes place after the campaign is activated. Otherwise, calculated since campaign activation time.',
  birthday: 'Messages will be sent a set number of days before a birthday. A message won\'t be sent if the campaign is activated less than this number of days before a birthday.',
};

import { DateTime, DateTimeOptions, ToISOTimeOptions } from 'luxon';

export const US_DATETIME_FORMAT_TIMEZONE = 'LL/dd/yyyy h:mm a ZZZZ';
export const US_DATETIME_FORMAT = 'LL/dd/yyyy h:mm a';
export const US_DATE_FORMAT = 'LL/dd/yyyy';

export const usLocale = 'en-US';
export const newYorkTimeZone = 'America/New_York';

export type DateResolution = 'day' | 'week' | 'month' | 'year';

// to be refacotred TC

export function isoDateStringToUsDateFormat(source?: string | null, formatter?: string) {
  const formattetToUse = formatter ?? US_DATETIME_FORMAT;
  if (!source) {
    return null;
  }
  return DateTime
    .fromISO(source)
    .setZone(newYorkTimeZone)
    .setLocale(usLocale)
    .toFormat(formattetToUse);
}

export const formatDateParamForResolutionControl = (resolution: string, date: string) => {
  const paramDateFormat = 'LL-dd-yyyy';
  switch (resolution) {
    case 'day':
      return isoDateStringToUsDateFormat(date, paramDateFormat);
    case 'week':
      const start = DateTime
        .fromISO(date)
        .setZone(newYorkTimeZone)
        .setLocale(usLocale)
        .startOf('week')
        .toFormat(paramDateFormat);

      const end = DateTime
        .fromISO(date)
        .setZone(newYorkTimeZone)
        .setLocale(usLocale)
        .startOf('week')
        .toFormat(paramDateFormat);

      return start + '-' + end;
    case 'month':
      return DateTime
        .fromISO(date)
        .setZone(newYorkTimeZone)
        .setLocale(usLocale)
        .toFormat('yyyyLL');
    case 'year':
      return DateTime
        .fromISO(date)
        .setZone(newYorkTimeZone)
        .setLocale(usLocale)
        .toFormat('yyyy');
    default: {
      return null;
    }
  }
};

export const formatDateParamAsNumber = (date: string): number => {
  return +DateTime
    .fromISO(date)
    .toFormat('yyyyLLdd');
};

export const getUtcNowString = (): string => {
  return DateTime.now().setZone('utc').toISO();
};

export const addDaysOffset = (date: string, offset: number): string => {
  return DateTime.fromISO(date, { setZone: true }).plus({ days: offset }).toISO();
};

export const formatDateTimeToZone = (date: string): string => {
  return DateTime.fromISO(date).setZone(newYorkTimeZone).toFormat(US_DATETIME_FORMAT);
};

export const formatDateToZone = (date: string): string => {
  return DateTime.fromISO(date).setZone(newYorkTimeZone).toFormat(US_DATE_FORMAT);
};

export const getDate = (date: string, options?: DateTimeOptions): string => {
  return DateTime.fromISO(date, { setZone: true, ...options }).toISODate();
};

export const getTime = (date: string, options?: DateTimeOptions): string => {
  const localDate = DateTime.fromISO(date, { setZone: true, ...options }).toISOTime();
  return localDate;
};

export const mergeDateAndTime = (date: string, time: string, zone?: string, options?: ToISOTimeOptions) => {
  date = date ?? DateTime.now().toUTC().toISO();
  time = time ?? DateTime.now().toUTC().toISOTime();

  const d = DateTime.fromISO(date, { setZone: true });
  const t = DateTime.fromISO(time, { setZone: true });
  const converted = DateTime.fromObject({ year: d.year, month: d.month, day: d.day, hour: t.hour, minute: t.minute, second: t.second, zone: zone || 'utc' }).toISO(options);
  return converted;
};

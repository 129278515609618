import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getReportsGraphQlClient } from '../../api/clients/getReportsGraphQlClient';
import {
  addFetchedCases,
  addFetchedCasesWithFailureReason,
  rejectedPayloadToFailureData,
  serializeBusinessError,
} from '../../api/common/fetched';
import { initialState } from './reports.state';
import { GamesDefaultPageSize, GameSort, GamesPagination, GameState } from './models/game';
import { availableReportTypesQuery } from './queries/availableReportTypesQuery';
import { ReportTypeCapabilityEnum } from './models/reportTypeCapabilityEnum';
import { reportTypeQuery } from './queries/reportTypeQuery';
import { cardBatchesQuery } from './queries/cardBatchesQuery';
import { pullTabsZReportsQuery } from './queries/pullTabsZReportsQuery';
import { foodBevZReportsQuery } from './queries/foodBevZReportsQuery';
import { ReportParameterValue } from './models/reportParameterValue';
import { ReportModuleEnum } from './models/reportModuleEnum';
import { requestReportGeneration as requestReportGenerationAction } from './actions/requestReportGeneration.action';
import {
  updateReportGenerationStatus as updateReportGenerationStatusAction,
} from './actions/updateReportGenerationStatus.action';
import { RootState } from '../root.reducer';
import { get } from '../../api/clients/RestClient';
import { RedeemedPromotion } from './models/redeemedPromotion';
import { ReportParameterWithValue } from '../../components/reports/helpers/reportParameterValuesHelper';
import { getReportParametersForRequest } from '../../components/reports/helpers/getReportParametersForRequest';
import moment from 'moment';
import { DEFAULT_LOCALE, DEFAULT_ZONE } from '../../components/reports/helpers/reportDateTimeService';
import { US_DATETIME_FORMAT_TIMEZONE } from '../../utility/formatters/formatDate';
import { DateTime } from 'luxon';
import { trimLeading1 } from '../../utility/formatters/formatInteger';
import { Till, TillSort, TillsPagination } from './models/till';

const SUPPORTED_REPORTS_API_VERSION = 4;
const SUPPORTED_REPORTS_CAPABILITIES: ReportTypeCapabilityEnum[] = [];

export const getAvailableReportTypes = createAsyncThunk(
  'reports/getAvailableReportTypes',
  async () => {
    const reportTypes = await availableReportTypesQuery(SUPPORTED_REPORTS_API_VERSION);
    return reportTypes.filter(report =>
      report.requiredCapabilities == null
      || report.requiredCapabilities.every(capability => SUPPORTED_REPORTS_CAPABILITIES.includes(capability)));
  },
);

export const getReportPermission = createAsyncThunk(
  'reports/getReportPermission',
  async () => {
    const graphqlResult = await getReportsGraphQlClient().mutate<{ myPermissions: { name: string }[] }>(
      `query {
      myPermissions{name}
    }`
    );
    let result = graphqlResult.myPermissions.map(x => x.name);
    if (result) {
      result = result.filter(x => x.startsWith('reports.report.') && x.endsWith('.view'));
    }
    return result;
  });

export const getReportType = createAsyncThunk(
  'reports/getReportType',
  async (args: { reportTypeId: string; reportVersion: number }) => {
    return await reportTypeQuery(args.reportTypeId, args.reportVersion);
  },
);

export const getCardBatches = createAsyncThunk(
  'reports/getCardBatches',
  async (args: {
    year?: number | null;
    month?: number | null;
    reportModule?: ReportModuleEnum | null;
  }) => {
    return await cardBatchesQuery(
      args.month?.toFixed(0) ?? null,
      args.year.toFixed(0) ?? null,
      args.reportModule ?? null);
  },
);

export const getZReports = createAsyncThunk(
  'reports/getZReports',
  async (args: {
    module: 'PullTabs' | 'FoodBev';
    year?: number | null;
    month?: number | null;
  }) => {
    const zReportsQuery = args.module === 'PullTabs' ? pullTabsZReportsQuery : foodBevZReportsQuery;
    return await zReportsQuery(args.month?.toFixed(0) ?? null, args.year.toFixed(0) ?? null);
  },
);

export const getGames = createAsyncThunk(
  'reports/getGames',
  async (
    { gameState, sort, pagination, isMixed }: {
      gameState?: GameState,
      sort?: GameSort,
      pagination?: GamesPagination,
      isMixed?: boolean
    }
  ) => {
    const result = await getReportsGraphQlClient().query<{
      games: {
        id: string;
        gameName: string;
        invoiceDate: string;
        formNumber: string;
        ticketCost: number;

        serialNo: string;
        ticketCount: number;
        topPrizeCount: number | null;
        topPrizeAmount: number | null;
        stateId: string | null;
      }[]
    }>(
      `query(
        $gameState: GameState,
        $sortColumn: GameSortColumn,
        $sortOrder: SortOrder,
        $skip: Int,
        $take: Int,
        $isMixed: Boolean
      ) {
        games(
          gameState: $gameState,
          sortColumn: $sortColumn,
          sortOrder: $sortOrder,
          skip: $skip,
          take: $take,
          isMixed: $isMixed
         ){
          id,
          gameName,
          serialNo,
          invoiceDate,
          formNumber,
          ticketCount,
          ticketCost,
          topPrizeCount,
          topPrizeAmount,
          stateId
        }
      }
    `,
      {
        gameState: gameState ?? null,
        sortColumn: sort?.sortField ?? 'GameName',
        sortOrder: sort?.sortOrder ?? 'Asc',
        skip: pagination?.skip ?? 0,
        take: pagination?.take ?? GamesDefaultPageSize,
        isMixed,
      }
    );

    return {
      games: (result?.games ?? []),
      sort: sort ?? {
        sortField: sort?.sortField ?? 'GameName',
        sortOrder: sort?.sortOrder ?? 'Asc',
      },
      pagination: pagination ?? {
        skip: pagination?.skip ?? 0,
        take: pagination?.take ?? GamesDefaultPageSize,
        pageNumber: pagination?.pageNumber ?? 1,
      },
    };
  });

export const getRedeemedPromotions = createAsyncThunk(
  'reports/redeemedPromotions',
  async (
    { parameters }: { parameters: ReportParameterWithValue[] },
    { getState },
  ) => {
    const state = getState() as RootState;
    const params = getReportParametersForRequest(parameters);
    const from = params.find(p => p.name === 'from').value;
    const fromFormated = moment(from, 'YYYY-MM-DD', true).format('MMDDYYYY');
    const to = params.find(p => p.name === 'to').value;
    const toFormated = moment(to, 'YYYY-MM-DD', true).format('MMDDYYYY');
    const result = await get<RedeemedPromotion[]>(`/reports/${state.org.selectedOrg.id}?from=${fromFormated}&to=${toFormated}&type=redeemed_promotions`);
    const mappedResult = result.map(r => {
      return {
        ...r,
        customerPhoneNumber: trimLeading1(r.customerPhoneNumber),
        claimedDate: DateTime.fromISO(r.claimedDate, { zone: DEFAULT_ZONE, locale: DEFAULT_LOCALE }).toFormat(US_DATETIME_FORMAT_TIMEZONE)
      };
    });
    return mappedResult;
  },
  { serializeError: serializeBusinessError },
);

export const getTills = createAsyncThunk(
  'reports/getTills',
  async (
    { dateFrom, dateTo, sort, pagination }: {
      dateFrom?: DateTime,
      dateTo?: DateTime,
      sort?: TillSort,
      pagination?: TillsPagination,
    }
  ) => {
    console.log('getTills', { dateFrom, dateTo, sort, pagination });
    const { tills } = await getReportsGraphQlClient().query<{ tills: Till[] }>(
      `query(
        $dateFrom: DateTime,
        $dateTo: DateTime,
        $skip: Int,
        $take: Int,
        $sortColumn: TillSortColumn,
        $sortOrder: SortOrder
      ) {
        tills(
          dateFrom: $dateFrom,
          dateTo: $dateTo,
          skip: $skip,
          take: $take,
          sortColumn: $sortColumn,
          sortOrder: $sortOrder
        ) {
          id
          name
          openDate
          openedByEmployee { login firstName lastName }
          closeDate
          closedByEmployee { login firstName lastName }
          isPowerhouse
          isDiamond
          isPullTabs
          isFoodBeverage
        }
      }`,
      {
        dateFrom: dateFrom?.toJSDate(),
        dateTo: dateTo?.toJSDate(),
        skip: pagination?.skip,
        take: pagination?.take,
        sortColumn: sort?.sortColumn,
        sortOrder: sort?.sortOrder,
      }
    );
    return { tills, pagination, sort };
  }
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    updateRecentParameterValues(state, action: PayloadAction<ReportParameterValue[]>) {
      const recentParameterValues: ReportParameterValue[] = [];
      [...action.payload, ...state.recentParameterValues].forEach(value => {
        if (!recentParameterValues.some(x => x.type === value.type && x.id === value.id)) {
          recentParameterValues.push(value);
        }
      });
      state.recentParameterValues = recentParameterValues;
    }
  },
  extraReducers: builder => {
    addFetchedCases(
      builder, getAvailableReportTypes,
      (state, fetched) => state.availableReportTypes = fetched);
    addFetchedCases(
      builder, getReportPermission,
      (state, fetched) => state.permissions = fetched);
    addFetchedCases(
      builder, getReportType,
      (state, fetched) => state.selectedReportType = fetched);
    addFetchedCases(
      builder, getCardBatches,
      (state, fetched) => state.cardBatches = fetched);
    addFetchedCases(
      builder, getZReports,
      (state, fetched) => state.zReports = fetched);
    addFetchedCases(
      builder, getGames,
      (state, fetched) => state.games = fetched);
    addFetchedCasesWithFailureReason(
      builder, requestReportGenerationAction,
      rejectedPayloadToFailureData,
      (state, fetched) => state.reportGenerationStatus = fetched);
    addFetchedCasesWithFailureReason(
      builder, updateReportGenerationStatusAction,
      rejectedPayloadToFailureData,
      (state, fetched) => state.reportGenerationStatus = fetched);
    addFetchedCases(
      builder, getRedeemedPromotions,
      (state, fetched) => state.redeemedPromotions = fetched);
    addFetchedCases(
      builder, getTills,
      (state, fetched) => state.tills = fetched);
  },
});

export const { updateRecentParameterValues } = reportsSlice.actions;

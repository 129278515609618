import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import {
  QuickBooksSaleCategoryMappingInput,
  QuickBooksSaleCategorySetup,
} from '../models/QuickBooksSalesSetup';

export async function saveQuickBooksSaleCategoryMappingMutation(
  mappings: QuickBooksSaleCategoryMappingInput[],
  autoSyncSales: boolean,
  syncPtabs: boolean,
  syncFoodbev: boolean,
): Promise<QuickBooksSaleCategorySetup> {
  const result = await getQuickBooksGraphQlClient().query<{
    saveQuickBooksSaleCategoryMapping: QuickBooksSaleCategorySetup;
  }>(
    `
      mutation($mappings: [QuickBooksSaleCategoryMappingInput!]!, $autoSyncSales: Boolean!, $syncPtabs: Boolean!, $syncFoodbev: Boolean!){
        saveQuickBooksSaleCategoryMapping(mappings: $mappings, autoSyncSales: $autoSyncSales, syncPtabs: $syncPtabs, syncFoodbev: $syncFoodbev) {
          mappings {
            posSaleCategoryId
            posSaleCategoryName
            quickBooksAccountId
            quickBooksAccountName
          }
          autoSyncSales
          syncPtabs
          syncFoodbev
        }
      }
    `,
    { mappings, autoSyncSales, syncPtabs, syncFoodbev }
  );
  return result.saveQuickBooksSaleCategoryMapping;
}

import { createFetched, Fetched } from '../../api/common/fetched';
import { CardPaymentBatchSummary } from './models/cardPaymentBatchSummary';
import { GameInfo, GameSort, GamesPagination } from './models/game';
import { ReportParameterValue } from './models/reportParameterValue';
import { ReportType } from './models/reportType';
import { ReportTypeHeader } from './models/reportTypeHeader';
import { ZReportDateRange } from './models/zReportDateRange';
import { RequestReportGenerationResult } from './models/reportGenerationRequest';
import { RedeemedPromotion } from './models/redeemedPromotion';
import { Till, TillSort, TillsPagination } from './models/till';

export interface ReportsState {
  availableReportTypes: Fetched<ReportTypeHeader[], string>;
  selectedReportType: Fetched<ReportType, string>;
  zReports: Fetched<ZReportDateRange[], string>;
  cardBatches: Fetched<CardPaymentBatchSummary[], string>;
  games: Fetched<{ games: GameInfo[]; sort: GameSort; pagination: GamesPagination; }, string>;
  recentParameterValues: ReportParameterValue[];
  permissions: Fetched<ReadonlyArray<string>, string>;
  reportGenerationStatus: Fetched<RequestReportGenerationResult>;
  redeemedPromotions: Fetched<RedeemedPromotion[], string>;
  tills: Fetched<{ tills: Till[], sort: TillSort; pagination: TillsPagination; }, string>;
}

export const initialState: ReportsState = {
  availableReportTypes: createFetched(),
  selectedReportType: createFetched(),
  zReports: createFetched(),
  cardBatches: createFetched(),
  games: createFetched(),
  recentParameterValues: [],
  permissions: createFetched(),
  reportGenerationStatus: createFetched(),
  redeemedPromotions: createFetched(),
  tills: createFetched(),
};

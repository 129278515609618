import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { initialState } from './auth.state';
import { Auth } from 'aws-amplify';
import {
  addFetchedCases,
  addFetchedCasesWithFailureReason,
  rejectedPayloadToStringFailureData,
  serializeBusinessError,
} from '../../api/common/fetched';
import { AuthUser } from './models/AuthUser';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { SelectedOrgLocalStorageKey } from '../org/org.slice';
import { post } from '../../api/clients/RestClient';

const getUserEmail = (authUser: CognitoUser) => new Promise(resolve => {
  authUser.getUserAttributes((err, result) => {
    resolve(result.find(r => r.getName() === 'email').getValue());
  });
});

export const getAuthUser = createAsyncThunk(
  'auth/getAuthUser',
  async () => {
    const authUser = await Auth.currentAuthenticatedUser();
    const session = await Auth.userSession(authUser);
    if (authUser && session && session.isValid()) {
      const email = await getUserEmail(authUser);
      return { name: authUser.getUsername(), email } as AuthUser;
    }
    return null;
  });

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (
    { email }: { email: string },
  ) => {
    await post(
      '/integrations/license-manager-graphql',
      {
        query: `
          mutation($input: CreatePasswordSetLinkInput!) {
            createPasswordSetLink(input: $input) {
              success
            }
          }
        `,
        variables: { input: { userId: email } },
      });
    return email;
  }, {
    serializeError: serializeBusinessError,
  });

export const changePasswordWithCode = createAsyncThunk(
  'auth/changePasswordWithCode',
  async (
    { activationCode, newPassword }: { activationCode: string, newPassword: string },
  ) => {
    await post(
      '/integrations/license-manager-graphql',
      {
        query: `
          mutation($input: SetUserPasswordInput!) {
            setUserPassword(input: $input) {
              success
            }
          }
        `,
        variables: {
          input: {
            activationCode,
            newPassword,
          },
        },
      });
  }, {
    serializeError: serializeBusinessError,
  });

export const markVisitedPasswordSetLink = createAsyncThunk(
  'auth/markVisitedPasswordSetLink',
  async (
    { activationCode }: { activationCode: string },
  ) => {
    await post(
      '/integrations/license-manager-graphql',
      {
        query: `
          mutation($input: MarkVisitedPasswordSetLinkInput!) {
            markVisitedPasswordSetLink(input: $input) {
              success
            }
          }
        `,
        variables: {
          input: {
            activationCode,
          },
        },
      });
  }, {
    serializeError: serializeBusinessError,
  });

export const completeNewPassword = createAsyncThunk(
  'auth/completeNewPassword',
  async (
    { user, newPass }: { user: CognitoUser; newPass: string },
    { dispatch },
  ) => {
    await Auth.completeNewPassword(user, newPass, {});
    dispatch(getAuthUser());
  });

export const logInUser = createAsyncThunk(
  'auth/logInUser',
  async (
    { username, password }: { username: string; password: string },
    { dispatch },
  ) => {
    const cognitoUser = await Auth.signIn(username.toLowerCase(), password);
    let isNewPasswordRequired = false;
    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      isNewPasswordRequired = true;
    } else {
      dispatch(getAuthUser());
    }
    return { username: username.toLowerCase(), isNewPasswordRequired, cognitoUser };
  });

export const logOutUser = createAsyncThunk(
  'auth/logOutUser',
  async (
    { redirect = null }: { redirect?: string | null },
  ) => {
    await Auth.signOut();
    localStorage.removeItem(SelectedOrgLocalStorageKey);
    window.location.href = redirect ? redirect : '/';
  });

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(
      builder, getAuthUser,
      (state, fetched) => state.authUser = fetched);
    addFetchedCases(
      builder, logInUser,
      (state, fetched) => state.logInUser = fetched);
    addFetchedCasesWithFailureReason(
      builder, changePasswordWithCode,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.changePasswordWithCode = fetched);
    addFetchedCasesWithFailureReason(
      builder, resetPassword,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.resetPassword = fetched);
    addFetchedCasesWithFailureReason(
      builder, markVisitedPasswordSetLink,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.markVisitedPasswordSetLink = fetched);
    addFetchedCases(
      builder, completeNewPassword,
      (state, fetched) => state.completeNewPassword = fetched);
  },
});

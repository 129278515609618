import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { serializeBusinessError } from '../../../api/common/fetched';
import { quickBooksAuthorizationDataQuery } from '../queries/quickBooksAuthorizationDataQuery';
import { QuickBooksAuthorizationData } from '../models/QuickBooksAuthorizationData';

export const getQuickBooksAuthorizationData = createAsyncThunk<QuickBooksAuthorizationData, undefined, AppThunkConfig>(
  'quickbooks/getQuickBooksAuthorizationData',
  async () => await quickBooksAuthorizationDataQuery(),
  {
    serializeError: serializeBusinessError,
  });

import { combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { authSlice } from './auth/auth.slice';
import { orgSlice } from './org/org.slice';
import navbar from './navbar/navbar.reducer';
import layout from './layout/layout.reducer';
import { cruiseControlSlice } from './cruiseControl/cruiseControl.slice';
import { autopilotSlice } from './autopilot/autopilot.slice';
import { announcementSlice } from './announcement/announcement.slice';
import { targetSlice } from './target/target.slice';
import { promotionSlice } from './promotion/promotion.slice';
import { rewardSlice } from './reward/reward.slice';
import { membersSlice } from './members/members.slice';
import { deviceSlice } from './device/device.slice';
import { activitySlice } from './activity/activity.slice';
import { reportsSlice } from './reports/reports.slice';
import { configSlice } from './config/config.slice';
import { paymentSlice } from './payment/payment.slice';
import { quickbooksSlice } from './quickbooks/quickbooks.slice';

const combinedReducer = combineReducers({
  auth: authSlice.reducer,
  org: orgSlice.reducer,
  announcement: announcementSlice.reducer,
  activity: activitySlice.reducer,
  reward: rewardSlice.reducer,
  target: targetSlice.reducer,
  navbar,
  layout,
  cruiseControl: cruiseControlSlice.reducer,
  promotion: promotionSlice.reducer,
  autopilot: autopilotSlice.reducer,
  members: membersSlice.reducer,
  device: deviceSlice.reducer,
  reports: reportsSlice.reducer,
  config: configSlice.reducer,
  payment: paymentSlice.reducer,
  quickbooks: quickbooksSlice.reducer
});

type GlobalState = ReturnType<typeof combinedReducer>;

export const rootReducer: typeof combinedReducer = (state, action): GlobalState => {
  return combinedReducer(state, action);
};

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = ThunkDispatch<RootState, undefined, undefined>;

export type AppThunkAction = (dispatch: AppDispatch, getState: () => RootState) => Promise<void> | void;

export type AppThunkConfig = {
  state: RootState,
  dispatch: AppDispatch,
};

import axios from 'axios';
import { processError } from '../common/errorHandling';
import { Auth } from 'aws-amplify';

export const baseImageUrl = process.env.REACT_APP_ASSETS_URL;

export const getImage = (image: string) => {
  return baseImageUrl + '/' + image;
};

export const maxFileSize = 350;

export const RestClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_REWARDS_API_URL,
});

async function getUser() {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch {
    return null;
  }
}

RestClient.interceptors.request.use(async axiosConfig => {
  const user = await getUser();
  if (user !== null) {
    axiosConfig.headers.authorization = 'Bearer ' + user.signInUserSession.accessToken.jwtToken;
  }
  return axiosConfig;
});

export const get = async <TData>(path: string): Promise<TData | undefined> => {
  try {
    const response = await RestClient.get(path);

    if (!response || !response.data) {
      throw new Error('Received malformed response.');
    }
    return response.data;
  } catch (error) {
    processError(error);
  }
};

// tslint:disable-next-line:no-any
export const post = async <TData>(path: string, data: any): Promise<TData | undefined> => {
  try {
    const response = await RestClient.post(path, data);

    if (!response || !response.data) {
      throw new Error('Received malformed response.');
    }
    return response.data;
  } catch (error) {
    processError(error);
  }
};

// tslint:disable-next-line:no-any
export const put = async <TData>(path: string, data: any): Promise<TData | undefined> => {
  try {
    const response = await RestClient.put(path, data);

    if (!response || !response.data) {
      throw new Error('Received malformed response.');
    }
    return response.data;
  } catch (error) {
    processError(error);
  }
};

export const del = async <TData>(path: string): Promise<TData | undefined> => {
  try {
    const response = await RestClient.delete(path);

    if (!response || !response.data) {
      throw new Error('Received malformed response.');
    }
    return response.data;
  } catch (error) {
    processError(error);
  }
};

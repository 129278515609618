import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { del, get, post, put } from '../../api/clients/RestClient';
import { addFetchedCases } from '../../api/common/fetched';
import { initialState } from './target.state';

export const getGroups = createAsyncThunk(
  'target/getGroups',
  async ({ orgId }: { orgId: string }) => {
    // tslint:disable-next-line:no-any
    const reply = await get<any>('/targets/' + orgId);
    const targets = reply.targets.filter(p => p.name !== 'all');
    return targets;
  });

export const createGroup = createAsyncThunk(
  'target/createGroup',
  async ({ orgId, name }: { orgId: string, name: string }) => {
    await post<void>('/targets/' + orgId, { name });
  });

export const updateCustomGroup = createAsyncThunk(
  'target/updateCustomGroup',
  async ({ orgId, groupId, name }: { orgId: string, groupId: string, name: string }) => {
    await put<void>('/targets/' + orgId + '/' + groupId, { name });
  });

export const updateSystemGroup = createAsyncThunk(
  'target/updateSystemTarget',
  async ({ orgId, groupId, groupValue }: { orgId: string, groupId: string, groupValue: string }) => {
    await put<void>('/targets/' + orgId + '/' + groupId, { value: groupValue });
  });

export const deleteGroup = createAsyncThunk(
  'target/deleteGroup',
  async ({ orgId, groupId }: { orgId: string, groupId: string }) => {
    await del<void>('/targets/' + orgId + '/' + groupId);
  });

export const targetSlice = createSlice({
  name: 'target',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(builder, getGroups,
      (state, fetched) => state.groups = fetched,
    );
    addFetchedCases(builder, createGroup,
      (state, fetched) => state.createGroup = fetched,
    );
    addFetchedCases(builder, updateCustomGroup,
      (state, fetched) => state.updateCustomGroup = fetched,
    );
    addFetchedCases(builder, updateSystemGroup,
      (state, fetched) => state.updateSystemGroup = fetched,
    );
    addFetchedCases(builder, deleteGroup,
      (state, fetched) => state.deleteGroup = fetched,
    );
  },
});

import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksAuthorizationData } from '../models/QuickBooksAuthorizationData';

export async function quickBooksAuthorizationDataQuery(): Promise<QuickBooksAuthorizationData> {
  const result = await getQuickBooksGraphQlClient().query<{ quickBooksAuthorizationData: QuickBooksAuthorizationData }>(
    `
      query{
        quickBooksAuthorizationData(useLocalhost: false) {
          authorizeUrl
        }
      }
    `);
  return result.quickBooksAuthorizationData;
}

import { createFetched, Fetched } from '../../api/common/fetched';
import { StripeIntent, StripePaymentMethodType } from './models/stripeIntent';
import { VenueSubscription } from './models/venueSubscription';

export interface PaymentState {
  confirmCardSetup: Fetched<void, string>;
  confirmCardPayment: Fetched<void, string>;
  createPayment: Fetched<Readonly<{
    intentId?: string | null,
    intentType?: 'payment' | 'setup' | null,
    paymentMethodType?: StripePaymentMethodType | null,
    paymentMethodId?: string | null,
  }>, string>;
  changePayment: Fetched<Readonly<string>, string>;
  venueSubscription: Fetched<Readonly<VenueSubscription>, string>;
  updateBillingData: Fetched<void, string>;
  paymentSetup: Fetched<Readonly<StripeIntent>, string>;
  createSubscription: Fetched<Readonly<{
    intentId?: string | null,
    intentType?: 'payment' | 'setup' | null,
    paymentMethodType?: StripePaymentMethodType | null,
    paymentMethodId?: string | null,
  }>, string>;
}

export const initialState: PaymentState = {
  confirmCardPayment: createFetched(),
  confirmCardSetup: createFetched(),
  createPayment: createFetched(),
  changePayment: createFetched(),
  venueSubscription: createFetched(),
  updateBillingData: createFetched(),
  paymentSetup: createFetched(),
  createSubscription: createFetched(),
};

import { createSlice } from '@reduxjs/toolkit';
import {
  addFetchedCasesWithFailureReason,
  rejectedPayloadToStringFailureData,
} from '../../api/common/fetched';
import { initialState } from './quickbooks.state';
import { getQuickBooksIntegrationStatus } from './actions/getQuickBooksIntegrationStatus';
import { connectToQuickBooks } from './actions/connectToQuickBooks';
import { getQuickBooksAuthorizationData } from './actions/getQuickBooksAuthorizationData';
import { getQuickBooksSalesSetup } from './actions/getQuickBooksSalesSetup';
import { saveQuickBooksSaleCategoryMapping } from './actions/saveQuickBooksSaleCategoryMapping';
import { setQuickBooksSyncStatus } from './actions/setQuickBooksSyncStatus';
import { getQuickBooksSyncDays } from './actions/getQuickBooksSyncDays';
import { syncTimesheetsWithQuickbooks } from './actions/syncTimesheetsWithQuickbooks';
import { saveQuickBooksTimesheetsMapping } from './actions/saveQuickBooksTimesheetsMapping';
import { getTimesheetsSetup } from './actions/getTimesheetsSetup';
import { getEmployees } from './actions/getEmployees';
import { getSaleCategoriesAndAccounts } from './actions/getSaleCategoriesAndAccounts';
import { syncSalesWithQuickbooks } from './actions/syncSalesWithQuickbooks';
import { getQuickBooksSyncLogs } from './actions/getQuickBooksSyncLogs';
import { disconnectFromQuickBooks } from './actions/disconnectFromQuickBooks';

export const quickbooksSlice = createSlice({
  name: 'quickbooks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCasesWithFailureReason(
      builder, getQuickBooksIntegrationStatus,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.quickBooksIntegrationStatus = fetched);
    addFetchedCasesWithFailureReason(
      builder, getQuickBooksAuthorizationData,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.quickBooksAuthorizationData = fetched);
    addFetchedCasesWithFailureReason(
      builder, connectToQuickBooks,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.connectToQuickBooks = fetched);
    addFetchedCasesWithFailureReason(
      builder, disconnectFromQuickBooks,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.disconnectFromQuickBooks = fetched);
    addFetchedCasesWithFailureReason(
      builder, getTimesheetsSetup,
      rejectedPayloadToStringFailureData,
      (state, fetched) => (state.quickBooksTimesheetsSetup = fetched));
    addFetchedCasesWithFailureReason(
      builder, saveQuickBooksTimesheetsMapping,
      rejectedPayloadToStringFailureData,
      (state, fetched) => (state.saveEmployeeMappingOperation = fetched));
    addFetchedCasesWithFailureReason(
      builder, getEmployees,
      rejectedPayloadToStringFailureData,
      (state, fetched) => (state.getEmployeesOperation = fetched));
    addFetchedCasesWithFailureReason(
      builder, getQuickBooksSalesSetup,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.quickBooksSalesSetup = fetched);
    addFetchedCasesWithFailureReason(
      builder, saveQuickBooksSaleCategoryMapping,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.saveQuickBooksSaleCategoryMapping = fetched);
    addFetchedCasesWithFailureReason(
      builder, getSaleCategoriesAndAccounts,
      rejectedPayloadToStringFailureData,
      (state, fetched) => (state.getSaleCategoriesAndAccountsOperation = fetched));
    addFetchedCasesWithFailureReason(
      builder, setQuickBooksSyncStatus,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.setQuickBooksSyncStatus = fetched);
    addFetchedCasesWithFailureReason(
      builder, getQuickBooksSyncDays,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.getQuickBooksSyncDays = fetched);
    addFetchedCasesWithFailureReason(
      builder, syncTimesheetsWithQuickbooks,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.syncTimesheetsWithQuickbooks = fetched);
    addFetchedCasesWithFailureReason(
      builder, syncSalesWithQuickbooks,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.syncSalesWithQuickbooks = fetched);
    addFetchedCasesWithFailureReason(
      builder, getQuickBooksSyncLogs,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.latestSyncLogs = fetched);
  },
});

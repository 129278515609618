import { Org } from './models/org';
import { get } from '../../api/clients/RestClient';

export const getOrgFromServer = async (orgId: string): Promise<Org | null> => {
  const org = await get<Org>(`/orgs/${orgId}`);
  return org;
};

export const getOrgsFromServer = async (): Promise<Org[]> => {
  const reply = await get<{ orgs: Org[] }>('/orgs');
  return reply.orgs ?? [];
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { del, get, post, put } from '../../api/clients/RestClient';
import { addFetchedCases } from '../../api/common/fetched';
import { Target } from '../target/models/target';
import { initialState } from './announcement.state';
import { Announcement, AnnouncementResponse } from './models/announcement';
import { AppThunkConfig } from '../root.reducer';

export const getAnnouncements = createAsyncThunk(
  'announcement/getAnnouncements',
  async (
    { orgId }: { orgId: string }
  ) => {
    // tslint:disable-next-line:no-any
    const reply = await get<any>('/announcements/' + orgId);

    const anouncements: Announcement[] = reply.announcements.map(p => {
      return {
        id: p.id,
        announcement: p.content,
        status: p.status,
        scheduledDate: p.scheduledDate,
        targets: p.targets,
        sent: p.sentCount,
        totalEstimatedSmsPartsCount: p.totalEstimatedSmsPartsCount,
      };
    });

    return anouncements;
  });

export const getAnnouncement = createAsyncThunk(
  'announcement/getAnnouncement',
  async (
    { orgId, id }: { orgId: string, id: string }
  ) => {
    // tslint:disable-next-line:no-any
    const result = await get<any>('/announcements/' + orgId + '/' + id);

    const announcement: Announcement = {
      id: result.id,
      announcement: result.content,
      status: result.status,
      scheduledDate: result.scheduledDate,
      targets: result.targets,
      sent: result.sentCount,
      createdDate: result.createdDate,
    };

    return announcement;
  });

export type SendNewAnnouncementInput = {
  orgId: string;
  content: string;
  scheduledDate: string;
  targets: Target[];
  estimatedSmsPartsCount: number;
  isDraft: boolean;
};

export const sendNewAnnouncement = createAsyncThunk<AnnouncementResponse, SendNewAnnouncementInput, AppThunkConfig>(
  'announcement/sendNewAnnouncement',
  async (
    { orgId, content, scheduledDate, targets, estimatedSmsPartsCount, isDraft }
  ) => {
    const announcementCreate = {
      content,
      scheduledDate,
      targets: targets.map(p => p.id),
      estimatedSmsPartsCount,
      isDraft,
    };
    await post('/announcements/' + orgId, announcementCreate);
    return announcementCreate;
  });

export type SendDraftAnnouncementInput = SendNewAnnouncementInput & {
  id: string;
};

export const sendDraftAnnouncement = createAsyncThunk<AnnouncementResponse, SendDraftAnnouncementInput, AppThunkConfig>(
  'announcement/sendDraftAnnouncement',
  async (
    { orgId, id, content, targets, scheduledDate, estimatedSmsPartsCount, isDraft }
  ) => {
    const announcementUpdate = {
      content,
      scheduledDate,
      targets: targets.map(p => p.id),
      estimatedSmsPartsCount,
      isDraft,
    };

    await put('/announcements/' + orgId + '/' + id, announcementUpdate);
    return announcementUpdate;
  });

export type SaveDraftAnnouncementInput = {
  orgId: string;
  content: string;
  targets: Target[];
  estimatedSmsPartsCount: number;
  scheduledDate: string;
  isDraft: boolean;
};

export const saveDraftAnnouncement = createAsyncThunk<AnnouncementResponse, SaveDraftAnnouncementInput, AppThunkConfig>(
  'announcement/saveDraftAnnouncement',
  async (
    { orgId, content, targets, estimatedSmsPartsCount, scheduledDate, isDraft }
  ) => {
    const announcementSave = {
      content,
      scheduledDate,
      targets: targets.map(p => p.id),
      estimatedSmsPartsCount,
      isDraft,
    };
    await post('/announcements/' + orgId, announcementSave);
    return announcementSave;
  });

export type UpdateDraftAnnouncementInput = SaveDraftAnnouncementInput & {
  id: string;
};

export const updateDraftAnnouncement = createAsyncThunk<AnnouncementResponse, UpdateDraftAnnouncementInput, AppThunkConfig>(
  'announcement/updateDraftAnnouncement',
  async (
    { orgId, id, content, targets, scheduledDate, isDraft }
  ) => {
    const announcementUpdate = {
      content,
      scheduledDate,
      targets: targets.map(p => p.id),
      isDraft,
    };

    await put('/announcements/' + orgId + '/' + id, announcementUpdate);
    return announcementUpdate;
  });

export const deleteAnnouncement = createAsyncThunk(
  'announcement/deleteAnnouncement',
  async (
    { orgId, id }: { orgId: string, id: string }
  ) => {
    // tslint:disable-next-line:no-any
    await del<any>('/announcements/' + orgId + '/' + id);
    return id;
  });

export const cancelAnnouncement = createAsyncThunk(
  'announcement/cancelAnnouncement',
  async (
    { orgId, id }: { orgId: string, id: string }
  ) => {
    // tslint:disable-next-line:no-any
    await put<any>('/announcements/' + orgId + '/' + id, { status: 'cancelled' });
    return id;
  });

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(
      builder, getAnnouncements,
      (state, fetched) => state.announcements = fetched);
    addFetchedCases(
      builder, getAnnouncement,
      (state, fetched) => state.getAnnouncement = fetched);
    addFetchedCases(
      builder, sendNewAnnouncement,
      (state, fetched) => state.createAnnouncement = fetched);
    addFetchedCases(
      builder, saveDraftAnnouncement,
      (state, fetched) => state.saveDraftAnnouncement = fetched);
    addFetchedCases(
      builder, updateDraftAnnouncement,
      (state, fetched) => state.updateDraftAnnouncement = fetched);
    addFetchedCases(
      builder, deleteAnnouncement,
      (state, fetched) => state.deleteAnnouncement = fetched);
    addFetchedCases(
      builder, cancelAnnouncement,
      (state, fetched) => state.cancelAnnouncement = fetched);
    addFetchedCases(
      builder, sendDraftAnnouncement,
      (state, fetched) => state.createAnnouncement = fetched);
  },
});

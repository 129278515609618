import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, put } from '../../api/clients/RestClient';
import Autopilot from './models/autopilot';
import { RootState } from '../root.reducer';
import { initialState } from './autopilot.state';
import {
  addFetchedCases,
  addFetchedCasesWithFailureReason,
  rejectedPayloadToStringFailureData,
} from '../../api/common/fetched';
import AutopilotUpdate from './models/autopilotUpdate';
import { AutopilotDetails, autopromoNotes } from './models/autopilotDetails';

export const getAutopilots = createAsyncThunk(
  'autopilot/getAutopilots',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const { autopromos } = await get<{ autopromos: Autopilot[] }>('/autopromos/' + state.org.selectedOrg.id);
    const result: Autopilot[] = autopromos.map(p => ({
      ...p,
      isActive: p.status === 'active',
    }));
    return result;
  });

export const getAutopilotById = createAsyncThunk(
  'autopilot/getAutopilotById',
  async (
    { id }: { id: string },
    { getState },
  ) => {
    const state = getState() as RootState;
    const autopromo = await get<AutopilotDetails>('/autopromos/' + state.org.selectedOrg.id + '/' + id);
    autopromo.isActive = autopromo.status === 'active';
    autopromo.note = autopromoNotes[autopromo.campaign];
    autopromo.customerContent = autopromo.customerContent === '?' ? '' : autopromo.customerContent;
    return autopromo;
  });

export const updateAutopilot = createAsyncThunk(
  'autopilot/updateAutopilot',
  async (
    { id, data }: { id: string; data: AutopilotUpdate },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    data.ruleParams = data.ruleParams.map(({ key, value }) => ({
      key,
      value: value.toString(),
    }));
    await put(`/autopromos/${state.org.selectedOrg.id}/${id}`, data);
    await dispatch(getAutopilots());
  });

export const autopilotSlice = createSlice({
  name: 'autopilot',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(
      builder, getAutopilots,
      (state, fetched) => state.autopilots = fetched,
    );
    addFetchedCases(
      builder, getAutopilotById,
      (state, fetched) => state.autopilot = fetched,
    );
    addFetchedCasesWithFailureReason(
      builder, updateAutopilot,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.updateOperation = fetched,
    );
  },
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestReportGenerationResult } from '../models/reportGenerationRequest';
import { AppThunkConfig } from '../../root.reducer';
import { getReportsGraphQlClient } from '../../../api/clients/getReportsGraphQlClient';
import { serializeBusinessError } from '../../../api/common/fetched';

const getReportGenerationRequestQuery = async (id: string) => {
  const result = await getReportsGraphQlClient()
    .query<{ reportGenerationRequest: RequestReportGenerationResult }>(
      `query($id: ID!) {
        reportGenerationRequest(id: $id) { id requestStatus }
      }`,
      { id },
    );

  return result.reportGenerationRequest;
};

export const updateReportGenerationStatus = createAsyncThunk<RequestReportGenerationResult, { id: string }, AppThunkConfig>(
  'reports/updateReportGenerationStatus',
  async ({ id }) =>
    await getReportGenerationRequestQuery(id),
  {
    serializeError: serializeBusinessError,
  });

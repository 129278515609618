// tslint:disable: max-classes-per-file
// tslint:disable: no-any
export class NetworkError extends Error {
}

export class ServerErrors extends Error {
  constructor(public errors: ReadonlyArray<any>) {
    super('Server returned errors');
  }
}

export class BusinessError extends Error {
  constructor(public errors: ReadonlyArray<any>) {
    super('Server returned business errors');
  }
}

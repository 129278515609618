import { Draft } from '@reduxjs/toolkit';
import { OrgState } from '../org.state';
import { Fetched, LoadState } from '../../../api/common/fetched';
import { Org } from '../models/org';

function updateSubscription(toUpdate: Draft<Org>, data: Org) {
  toUpdate.billingPlanId = data.billingPlanId;
  toUpdate.accountType = data.accountType;
  toUpdate.subscriptionStatus = data.subscriptionStatus;
  toUpdate.smsSubscriptionStatus = data.smsSubscriptionStatus;
  toUpdate.validityDate = data.validityDate;
  toUpdate.smsLimit = data.smsLimit;
  toUpdate.smsCount = data.smsCount;
  toUpdate.memberLimit = data.memberLimit;
  toUpdate.memberCount = data.memberCount;
  toUpdate.billingName = data.billingName;
  toUpdate.billingEmail = data.billingEmail;
  toUpdate.customGroupsAllowed = data.customGroupsAllowed;
  toUpdate.smsPrice = data.smsPrice;

  if (toUpdate.billingAddress && data.billingAddress) {
    toUpdate.billingAddress.streetAddress1 = data.billingAddress.streetAddress1;
    toUpdate.billingAddress.streetAddress2 = data.billingAddress.streetAddress2;
    toUpdate.billingAddress.city = data.billingAddress.city;
    toUpdate.billingAddress.state = data.billingAddress.state;
    toUpdate.billingAddress.zipCode = data.billingAddress.zipCode;
  } else {
    toUpdate.billingAddress = data.billingAddress ? { ...data.billingAddress } : null;
  }
}

export function reloadCurrentOrgCaseSetter(state: Draft<OrgState>, fetched: Fetched<Org, string>) {
  if (fetched.data) {
    if (state.userOrgs.loadState === LoadState.Success) {
      const toUpdate = state.userOrgs.data.find(org => org.id === fetched.data.id);
      if (toUpdate) {
        updateSubscription(toUpdate, fetched.data);
      }
    }
    updateSubscription(state.selectedOrg, fetched.data);
  }
}

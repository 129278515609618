import { createFetched, Fetched } from '../../api/common/fetched';
import { Reward } from './models/reward';
export interface RewardState {
  rewards: Fetched<Reward[], string>;
  getReward: Fetched<Reward, string>;
  deleteReward: Fetched<void, string>;
  createReward: Fetched<void, string>;
  updateReward: Fetched<void, string>;
}

export const initialState: RewardState = {
  rewards: createFetched(),
  getReward: createFetched(),
  deleteReward: createFetched(),
  createReward: createFetched(),
  updateReward: createFetched(),
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { post } from '../../api/clients/RestClient';
import { addFetchedCases } from '../../api/common/fetched';
import { formatDateParamAsNumber } from '../../utility/formatters/formatDate';
import { prefixLeading1 } from '../../utility/formatters/formatInteger';
import { initialState } from './activity.state';
import { Activity } from './models/activity';

export type GetActivityProps = {
  orgId: string;
  startDate: string;
  endDate: string;
  phone: string | null;
  startKey?: string;
  limit?: number;
  sortField?: string;
  sortDirection?: string;
  activityTypes? : string[]
};

export const getActivities = createAsyncThunk(
  'activity/getActivities',
  async (
    {
      orgId,
      startDate,
      endDate,
      phone,
      startKey,
      limit,
      sortField,
      sortDirection,
      activityTypes,
    }: GetActivityProps
  ) => {
    const path = '/activities/' + orgId + '/search';
    // tslint:disable-next-line:no-any
    const data: any = {
      startDate: formatDateParamAsNumber(startDate),
      endDate: formatDateParamAsNumber(endDate),
    };

    if (phone != null && +phone !== 0) data.phone = prefixLeading1(+phone);
    if (startKey != null) data.startKey = startKey;
    if (limit != null) data.limit = limit;
    if (sortField != null) data.sortField = sortField;
    if (sortDirection != null) data.sortDirection = sortDirection;
    if (activityTypes != null) data.types = activityTypes;
    const respone = await post<{ activities: Activity[], lastEvaluatedKey?: string }>(path, data);

    return {
      activities: respone.activities,
      lastEvaluatedKey: respone.lastEvaluatedKey,
    };
  });

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(
      builder, getActivities,
      (state, fetched) => state.activities = fetched);
  },
});

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../root.reducer';
import { get, post, put } from '../../api/clients/RestClient';
import { AssignMemberToGroups, Member, MemberDto, MemberForDelete, MemberForImport } from './models/member';
import {
  addFetchedCases,
  addFetchedCasesWithFailureReason,
  rejectedPayloadToStringFailureData,
  serializeBusinessError,
} from '../../api/common/fetched';
import { initialState } from './members.state';
import { MemberCreate, MemberUpdate } from './models/memberUpdate';
import { DateTime } from 'luxon';
import { newYorkTimeZone } from '../../utility/formatters/formatDate';
import { MembersImportResult } from './models/membersImportResult';
import { Target } from '../target/models/target';
import { reloadCurrentOrg } from '../org/org.slice';

const maxChangeOptInOutItems = 25;

function mapDtoToMember(dto: MemberDto): Member {
  return {
    ...dto,
    firstName: dto.firstName ?? '',
    lastName: dto.lastName ?? '',
    targets: dto.targets.filter(t => t.name !== 'all'),
    isOptedIn: dto.optOutType === 'none',
  };
}

export const getMembers = createAsyncThunk(
  'members/getMembers',
  async (_, { getState }): Promise<Member[]> => {
    const state = getState() as RootState;
    const { members } = await get<{ members: MemberDto[] }>('/members/' + state.org.selectedOrg.id);
    return members.map(mapDtoToMember);
  });

export const getMemberById = createAsyncThunk(
  'members/getMemberById',
  async ({ memberId }: { memberId: string }, { getState }): Promise<Member> => {
    const state = getState() as RootState;
    const dto = await get<MemberDto>(`/members/${state.org.selectedOrg.id}/${memberId}`);
    return mapDtoToMember(dto);
  });

function dobToServerFormat(data: MemberUpdate | MemberCreate) {
  return data.birthday ? DateTime.fromISO(data.birthday, { zone: newYorkTimeZone }).toUTC().toISO() : undefined;
}

export const createMember = createAsyncThunk(
  'members/createMember',
  async (
    { data }: { data: MemberCreate; },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const request = {
      phone: data.phoneNumber,
      firstName: data.firstName,
      lastName: data.lastName,
      points: data.points,
      targets: data.groupIds,
      dob: dobToServerFormat(data),
      user: `Rewards/${state.auth.authUser.data?.email}`
    };
    const result = await post<void>(`/members/${state.org.selectedOrg.id}`, request);
    dispatch(reloadCurrentOrg(state.org.selectedOrg.id));
    return result;
  }, {
    serializeError: serializeBusinessError,
  });

export const updateMember = createAsyncThunk(
  'members/updateMember',
  async (
    { memberId, data }: { memberId: string; data: MemberUpdate; },
    { getState },
  ) => {
    const state = getState() as RootState;
    const request = {
      firstName: data.firstName,
      lastName: data.lastName,
      pointsChange: data.pointsChange,
      targets: data.groupIds,
      dob: dobToServerFormat(data),
      user: `Rewards/${state.auth.authUser.data?.email}`
    };
    await put<void>(`/members/${state.org.selectedOrg.id}/${memberId}`, request);
  }, {
    serializeError: serializeBusinessError,
  });

export const changeOptInOut = createAsyncThunk(
  'members/changeOptInOut',
  async ({ items }: { items: { memberId: string; isOptIn: boolean }[] }, { getState }) => {
    const state = getState() as RootState;
    for (let i = 0; i < items.length; i += maxChangeOptInOutItems) {
      await post(
        `/members/${state.org.selectedOrg.id}/batch-change-opt-out`,
        items.slice(i, i + maxChangeOptInOutItems));
    }
  }, {
    serializeError: serializeBusinessError,
  });

export const importMembers = createAsyncThunk(
  'members/importMembers',
  async (
    { members }: { members: MemberForImport[] },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const result = await post<MembersImportResult>(`/members/${state.org.selectedOrg.id}/batch`, members);
    dispatch(reloadCurrentOrg(state.org.selectedOrg.id));
    return result;
  }, {
    serializeError: serializeBusinessError,
  });

export const deleteMembers = createAsyncThunk(
  'members/deleteMembers',
  async (
    { members }: { members: MemberForDelete[] },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const result = await put<MembersImportResult>(`/members/${state.org.selectedOrg.id}/batch`, members);
    dispatch(reloadCurrentOrg(state.org.selectedOrg.id));
    return result;
  }, {
    serializeError: serializeBusinessError,
  });

export const bulkAssignMembersToGroup = createAsyncThunk(
  'members/bulkAssignMembersToGroup',
  async (
    { members }: { members: AssignMemberToGroups[] },
    { getState },
  ) => {
    const state = getState() as RootState;
    return await put<MembersImportResult>(`/members/${state.org.selectedOrg.id}/batch`, members);
  }, {
    serializeError: serializeBusinessError,
  });

export const bulkAssignMembersToNewGroup = createAsyncThunk(
  'members/bulkAssignMembersToNewGroup',
  async (
    { members, groupName }: { members: Member[], groupName: string },
    { getState },
  ) => {
    const state = getState() as RootState;
    const targetGroup = await post<Target>('/targets/' + state.org.selectedOrg.id, { name: groupName });
    const items = members.map((item: Member): AssignMemberToGroups =>
    ({
      phone: item.phone,
      targets: [
        { id: targetGroup.id, action: 'add' },
      ],
    }));
    return await put<MembersImportResult>(`/members/${state.org.selectedOrg.id}/batch`, items);
  }, {
    serializeError: serializeBusinessError,
  });

export const getSendSmsMembersCount = createAsyncThunk(
  'stats/targets',
  async ({ targets }: { targets: Target[] }, { getState }): Promise<number> => {
    const state = getState() as RootState;
    const targetsValue = targets.map(p => p.id).join();
    const dto = await get<{ optedInCount }>(`/stats/opt/${state.org.selectedOrg.id}?targetGroupIds=${targetsValue}`);
    return dto.optedInCount;
  }, { serializeError: serializeBusinessError, });

export const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(
      builder, getMembers,
      (state, fetched) => state.members = fetched,
    );
    addFetchedCases(
      builder, getMemberById,
      (state, fetched) => state.member = fetched,
    );
    addFetchedCasesWithFailureReason(
      builder, createMember,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.editOperation = fetched,
    );
    addFetchedCasesWithFailureReason(
      builder, updateMember,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.editOperation = fetched,
    );
    addFetchedCasesWithFailureReason(
      builder, changeOptInOut,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.editOperation = fetched,
    );
    addFetchedCasesWithFailureReason(
      builder, importMembers,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.importResult = fetched,
    );
    addFetchedCasesWithFailureReason(
      builder, deleteMembers,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.deleteMembers = fetched,
    );
    addFetchedCasesWithFailureReason(
      builder, bulkAssignMembersToGroup,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.bulkAssignMembersToGroup = fetched,
    );
    addFetchedCasesWithFailureReason(
      builder, bulkAssignMembersToNewGroup,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.bulkAssignMembersToNewGroup = fetched,
    );
    addFetchedCasesWithFailureReason(
      builder, getSendSmsMembersCount,
      rejectedPayloadToStringFailureData,
      (state, fetched) => state.getSendSmsMembersCount = fetched,
    );
  },
});

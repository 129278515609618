import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksIntegrationStatus } from '../models/QuickBooksIntegrationStatus';

export async function disconnectFromQuickBooksMutation(): Promise<QuickBooksIntegrationStatus> {
  const result = await getQuickBooksGraphQlClient().query<{ disconnectFromQuickBooks: QuickBooksIntegrationStatus }>(
    `
      mutation {
        disconnectFromQuickBooks {
          posOrgId
          isConnected
          isSyncEnabled
          autoSyncTimesheets
          autoSyncSales
        }
      }
    `, { });
  return result.disconnectFromQuickBooks;
}

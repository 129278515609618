import { createFetched, Fetched } from '../../api/common/fetched';
import { Config } from './models/config';
import { VenueConfig } from './models/venueConfig';

export interface ConfigState {
  venueSettings: Fetched<Config, string>;
  venueConfig: VenueConfig | null;
}

export const initialState: ConfigState = {
  venueSettings: createFetched(),
  venueConfig: null,
};

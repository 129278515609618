import { getReportsGraphQlClient } from '../../../api/clients/getReportsGraphQlClient';
import { ReportType } from '../models/reportType';

export async function reportTypeQuery(reportTypeId: string, reportVersion: number): Promise<ReportType> {
  const result = await getReportsGraphQlClient().query<{ reportType: ReportType }>(
    `
    query($reportTypeId: ID!, $reportVersion: Int!) {
      reportType(reportTypeId: $reportTypeId, reportVersion: $reportVersion) {
        reportTypeId
        version
        module
        group
        title
        isReceipt
        requiredCapabilities
        autoShowPreview
        parameters {
          title
          type
          isOptional
          ...on DateRangeReportTypeParameter {
            dateFromParameterName
            dateToParameterName
            dateFromDefaultValue
            dateToDefaultValue
          }
          ...on DateTimeRangeReportTypeParameter {
            dateFromParameterName
            dateToParameterName
            dateFromDefaultValue
            dateToDefaultValue
          }
          ...on DateReportTypeParameter {
            parameterName
            defaultValue
          }
          ...on YearReportTypeParameter {
            yearParameterName
            defaultYearValue
          }
          ...on YearQuarterReportTypeParameter {
            yearParameterName
            quarterParameterName
            defaultYearValue
            defaultQuarterValue
          }
          ...on YearMonthReportTypeParameter {
            yearParameterName
            monthParameterName
            defaultYearValue
            defaultMonthValue
          }
          ...on SingleChoiceListReportTypeParameter {
            parameterName
            items { key title }
            defaultValue
          }
          ...on GenerationTimeReportTypeParameter {
            parameterName
          }
          ...on CreditBatchReportTypeParameter {
            batchIdParameterName
            moduleParameterName
            merchantIdParameterName
          }
          ...on GameReportTypeParameter {
            parameterName
            gamesDealsOnly
            includeReturned
            includeInStock
          }
          ...on PtabsZReportReportTypeParameter {
            parameterName
          }
          ...on GameWeightsReportTypeParameter {
            parameterName
          }
          ...on FoodBevZReportReportTypeParameter {
            parameterName
          }
          ...on TillReportTypeParameter {
            parameterName
          }
        }
      }
    }
    `,
    {
      reportTypeId,
      reportVersion,
    }
  );

  return result.reportType;
}

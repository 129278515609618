import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { serializeBusinessError } from '../../../api/common/fetched';
import { saveQuickBooksSaleCategoryMappingMutation } from '../queries/saveQuickBooksSaleCategoryMappingMutation';
import { QuickBooksSaleCategorySetup, QuickBooksSalesSetupInput } from '../models/QuickBooksSalesSetup';

export const saveQuickBooksSaleCategoryMapping = createAsyncThunk<
  QuickBooksSaleCategorySetup, QuickBooksSalesSetupInput & { callback?: () => void }, AppThunkConfig>(
  'quickbooks/saveQuickBooksSaleCategoryMapping',
  async x => {
    const response = await saveQuickBooksSaleCategoryMappingMutation(x.mappings, x.autoSyncSales, x.syncPtabs, x.syncFoodbev);
    if (x.callback) {
      x.callback();
    }
    return response;
  },
  { serializeError: serializeBusinessError }
);

import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksSalesSetup } from '../models/QuickBooksSalesSetup';

export async function getQuickBooksSalesSetupQuery(): Promise<QuickBooksSalesSetup> {
  const result = await getQuickBooksGraphQlClient().query<QuickBooksSalesSetup>(
    `
      query {
        quickBooksSaleCategoryMapping {
          mappings {
            posSaleCategoryId
            posSaleCategoryName
            quickBooksAccountId
            quickBooksAccountName
          }
          autoSyncSales
          syncPtabs
          syncFoodbev
        }
        posSaleCategories {
          id
          type
          name
        }
        quickBooksAccounts {
          id
          name
        }
      }
    `
  );
  return result;
}

const integerFormatter = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 0 });

export const formatInteger = (value: number) => integerFormatter.format(value);

export function prefixLeading1(value: number): number {
  if (!value) {
    return null;
  }
  return +`1${value.toString()}`;
}

export function trimLeading1(value: number): number {
  if (!value) {
    return null;
  }
  return +value.toString().substring(1);
}

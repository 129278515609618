import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { serializeBusinessError } from '../../../api/common/fetched';
import { QuickBooksSalesSetup } from '../models/QuickBooksSalesSetup';
import { getSaleCategoriesAndAccountsQuery } from '../queries/getSaleCategoriesAndAccountsQuery';

export const getSaleCategoriesAndAccounts = createAsyncThunk<
  Pick<QuickBooksSalesSetup, 'posSaleCategories' | 'quickBooksAccounts'>,  undefined,  AppThunkConfig>(
  'quickbooks/getSaleCategoriesAndAccounts',
  async () => await getSaleCategoriesAndAccountsQuery(),
  { serializeError: serializeBusinessError }
);

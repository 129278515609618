import { getReportsGraphQlClient } from '../../../api/clients/getReportsGraphQlClient';
import { ZReportDateRange } from '../models/zReportDateRange';

export async function pullTabsZReportsQuery(month: string | null, year: string | null): Promise<ZReportDateRange[]> {
  const result = await getReportsGraphQlClient()
    .query<{ getZReportsDates: ZReportDateRange[] }>(
      'query($month: String, $year: String) { getZReportsDates(month: $month, year: $year) { id, fromDate, toDate, createdDate } }',
      { month, year }
    );

  return result.getZReportsDates;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { QuickBooksIntegrationStatus } from '../models/QuickBooksIntegrationStatus';
import { serializeBusinessError } from '../../../api/common/fetched';
import { getQuickBooksIntegrationStatus } from './getQuickBooksIntegrationStatus';
import { QuickBooksSyncStatusInput } from '../models/QuickBooksSyncStatusInput';
import { setQuickBooksSyncStatusMutation } from '../queries/setQuickBooksSyncStatusMutation';

export const setQuickBooksSyncStatus = createAsyncThunk<QuickBooksIntegrationStatus, QuickBooksSyncStatusInput, AppThunkConfig>(
  'quickbooks/setQuickBooksSyncStatus',
  async (input, { dispatch }) => {
    const result = await setQuickBooksSyncStatusMutation(input);
    if (result) {
      dispatch(getQuickBooksIntegrationStatus());
    }
    return result;
  },
  {
    serializeError: serializeBusinessError,
  });

import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksTimesheetsSetup } from '../models/QuickBooksTimesheetsSetup';

export const getQuickBooksTimesheetsSetupQuery = async (): Promise<QuickBooksTimesheetsSetup> =>
  await getQuickBooksGraphQlClient().query<QuickBooksTimesheetsSetup>(
    `
      query {
        quickBooksEmployees {
          id
          name
        }
        posEmployees {
          id
          name
        }
        quickBooksEmployeeMapping {
          mappings {
            posEmployeeId
            posEmployeeName
            quickBooksEmployeeId
            quickBooksEmployeeName
            syncEnabled
          }
          autoSyncTimesheets
        }
      }
    `
  );

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, post, put } from '../../api/clients/RestClient';
import DeviceInfo from './models/deviceInfo';
import { initialState } from './device.state';
import { addFetchedCases, serializeBusinessError } from '../../api/common/fetched';
import CognitoUser from './models/cognitoUser';

export const getDevices = createAsyncThunk(
  'device/getDevices',
  async (
    { orgId }: { orgId: string }
  ) => {
    const response = await get<{ devices: DeviceInfo[] }>(`/devices/${orgId}`);
    return response.devices;
  });

export const getDeviceByAppcode = createAsyncThunk(
  'device/getDeviceByAppcode',
  async (
    { orgId, appcode }: { orgId: string, appcode: string }
  ) => {
    // tslint:disable-next-line: no-any
    const response = await get<any>(`/accesscodes/${appcode}?orgId=${orgId}`);
    return response;
  }, {
    serializeError: serializeBusinessError
  });

export const registerCognitoUser = createAsyncThunk(
  'device/registerCognitoUser',
  async (
    { user }: { user: CognitoUser }
  ) => {
    // tslint:disable-next-line: no-any
    await post<any>('/register', user);
  }, {
    serializeError: serializeBusinessError
  });

export const updateDevice = createAsyncThunk(
  'device/updateDevice',
  async (
    { device }: { device: DeviceInfo }
  ) => {
    // tslint:disable-next-line:no-any
    await put<any>(
      '/devices',
      {
        id: device.id,
        name: device.name,
        status: device.status,
        username: device.subId
      }
    );
  });

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(
      builder, getDevices,
      (state, fetched) => state.devices = fetched);
    addFetchedCases(
      builder, updateDevice,
      (state, fetched) => state.updateDevice = fetched);
    addFetchedCases(
      builder, getDeviceByAppcode,
      (state, fetched) => state.getDeviceByAppcode = fetched);
    addFetchedCases(
      builder, registerCognitoUser,
      (state, fetched) => state.registerCognitoUser = fetched);
  },
});

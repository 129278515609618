import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import {
  QuickBooksEmployeeMappingInput,
  QuickBooksEmployeeSetup,
} from '../models/QuickBooksTimesheetsSetup';

export async function saveQuickBooksEmployeeMappingMutation(
  mappings: QuickBooksEmployeeMappingInput[],
  autoSyncTimesheets: boolean
): Promise<QuickBooksEmployeeSetup> {
  const result = await getQuickBooksGraphQlClient().query<{
    saveQuickBooksEmployeeMapping: QuickBooksEmployeeSetup;
  }>(
    `
      mutation($mappings: [QuickBooksEmployeeMappingInput!]!, $autoSyncTimesheets: Boolean!){
        saveQuickBooksEmployeeMapping(mappings: $mappings, autoSyncTimesheets: $autoSyncTimesheets) {
          mappings {
            posEmployeeId
            posEmployeeName
            quickBooksEmployeeId
            quickBooksEmployeeName
            syncEnabled
          }
          autoSyncTimesheets
        }
      }
    `,
    { mappings, autoSyncTimesheets }
  );
  return result.saveQuickBooksEmployeeMapping;
}

import { Fragment } from 'react';
import { toast } from 'react-toastify';
import { Check, X, AlertTriangle, Info } from 'react-feather';

type ToastProps = {
  message: string;
  title?: string | null;
};

const SuccessToast = ({ message, title }: ToastProps) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <div className="avatar bg-success avatar-sm">
          <span className="avatar-content">
            <Check size={12} />
          </span>
        </div>
        <h6 className="toast-title">{title ? title : 'Success!'}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {message}
      </span>
    </div>
  </Fragment>
);

const ErrorToast = ({ message, title }: ToastProps) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <div className="avatar bg-danger avatar-sm">
          <span className="avatar-content">
            <X size={12} />
          </span>
        </div>
        <h6 className="toast-title">{title ? title : 'Error!'}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {message}
      </span>
    </div>
  </Fragment>
);

const WarningToast = ({ message, title }: ToastProps) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <div className="avatar bg-warning avatar-sm">
          <span className="avatar-content">
            <AlertTriangle size={12} />
          </span>
        </div>
        <h6 className="toast-title">{title ? title : 'Warning!'}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {message}
      </span>
    </div>
  </Fragment>
);

const InfoToast = ({ message, title }: ToastProps) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <div className="avatar bg-info avatar-sm">
          <span className="avatar-content">
            <Info size={12} />
          </span>
        </div>
        <h6 className="toast-title">{title ? title : 'Info!'}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {message}
      </span>
    </div>
  </Fragment>
);

export const notifySuccess = (message: string, title: string | null = null, duration?: number) => {
  toast.success(
    <SuccessToast message={message} title={title} />,
    {
      autoClose: duration ?? undefined,
    },
  );
};
export const notifyError = (message: string, title: string | null = null, duration?: number) => {
  toast.error(
    <ErrorToast message={message} title={title} />,
    {
      autoClose: duration ?? undefined,
    },
  );
};
export const notifyWarning = (message: string, title: string | null = null, duration?: number) => {
  toast.warning(
    <WarningToast message={message} title={title} />,
    {
      autoClose: duration ?? undefined,
    },
  );
};
export const notifyInfo = (message: string, title: string | null = null, duration?: number) => {
  toast.info(
    <InfoToast message={message} title={title} />,
    {
      autoClose: duration ?? undefined,
    },
  );
};

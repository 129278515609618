import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getReportsGraphQlClient } from '../../api/clients/getReportsGraphQlClient';
import { addFetchedCases, LoadState } from '../../api/common/fetched';
import { initialState } from './config.state';
import { VenueSettings } from './models/venueSettings';
import { mapConfigEntryToVenueConfig } from './helpers/mapConfigEntryToVenueConfig';

export const getVenueSettings = createAsyncThunk(
  'config/getVenueSettings',
  async () => {
    const response = await getReportsGraphQlClient().query<VenueSettings>(
      `query {
        getVenueConfig {
          key,
          value
        }
        syncStatus {
          orgId,
          lastSyncDate
        }
      }`
    );
    return {
      venueSettings: response.getVenueConfig,
      syncStatus: response.syncStatus,
    };
  });

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(
      builder, getVenueSettings,
      (state, fetched) => {
        state.venueSettings = fetched;
        state.venueConfig = fetched.loadState === LoadState.Success
          ? mapConfigEntryToVenueConfig(fetched.data.venueSettings)
          : null;
      },
    );
  },
});

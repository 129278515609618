import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Campaign } from './models/campaign';
import { get } from '../../api/clients/RestClient';
import RewardStats from './models/rewardStats';
import PromotionStats from './models/promotion.stats';
import { initialState, ResolutionType } from './cruiseControl.state';
import { addFetchedCases } from '../../api/common/fetched';
import { DateTime } from 'luxon';
import { formatDateParamForResolutionControl } from '../../utility/formatters/formatDate';

export const getRewardStats = createAsyncThunk(
  'cruiseControl/getRewardStats',
  async (
    { orgId, resolution }: { orgId: string; resolution: ResolutionType },
  ) => {
    const formattedDate = formatDateParamForResolutionControl(resolution, DateTime.local().setZone('America/New_York').toString());
    const { stats } = await get<{ stats: RewardStats[] }>('stats/rewards/' + orgId + '?resolution=' + resolution + '&value=' + formattedDate);
    return stats;
  });

export const getPromotionStats = createAsyncThunk(
  'cruiseControl/getPromotionStats',
  async (
    { orgId, resolution }: { orgId: string; resolution: ResolutionType },
  ) => {
    const formattedDate = formatDateParamForResolutionControl(resolution, DateTime.local().setZone('America/New_York').toString());
    const { stats } = await get<{ stats: PromotionStats[] }>('stats/promos/' + orgId + '?resolution=' + resolution + '&value=' + formattedDate);
    return stats;
  });

export const getCampaigns = createAsyncThunk(
  'cruiseControl/getCampaigns',
  async (
    { orgId, resolution }: { orgId: string; resolution: ResolutionType },
  ) => {
    const formattedDate = formatDateParamForResolutionControl(resolution, DateTime.local().setZone('America/New_York').toString());
    const { stats } = await get<{ stats: Campaign[] }>('stats/autopromos/' + orgId + '?resolution=' + resolution + '&value=' + formattedDate);
    return stats;
  });

export const cruiseControlSlice = createSlice({
  name: 'cruiseControl',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(
      builder, getCampaigns,
      (state, fetched) => state.campaigns = fetched);
    addFetchedCases(
      builder, getPromotionStats,
      (state, fetched) => state.promotionStats = fetched);
    addFetchedCases(
      builder, getRewardStats,
      (state, fetched) => state.rewardStats = fetched);
  },
});

import { Fetched, createFetched } from '../../api/common/fetched';
import { AuthUser } from './models/AuthUser';
import { LogInUserResult } from './models/LogInUserResult';
import { FailureData } from '../../api/common/failure';

export type AuthView = 'SignIn' | 'ResetPassword' | 'ChangePassword' | 'FirstLogin';

export interface AuthState {
  username: string;
  authUser: Fetched<AuthUser, string>;
  logInUser: Fetched<LogInUserResult, string>;
  logOutUser: Fetched<void>;
  resetPassword: Fetched<string, FailureData<string>>;
  changePasswordWithCode: Fetched<void, FailureData<string>>;
  markVisitedPasswordSetLink: Fetched<void, FailureData<string>>;
  completeNewPassword: Fetched<void, string>;
}

export const initialState: AuthState = {
  username: '',
  authUser: createFetched(),
  logInUser: createFetched(),
  logOutUser: createFetched(),
  resetPassword: createFetched(),
  changePasswordWithCode: createFetched(),
  markVisitedPasswordSetLink: createFetched(),
  completeNewPassword: createFetched(),
};

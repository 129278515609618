import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { serializeBusinessError } from '../../../api/common/fetched';
import { saveQuickBooksEmployeeMappingMutation } from '../queries/saveQuickBooksEmployeeMappingMutation';
import { QuickBooksEmployeeSetup, QuickBooksTimesheetsSetupInput } from '../models/QuickBooksTimesheetsSetup';

export const saveQuickBooksTimesheetsMapping = createAsyncThunk<
  QuickBooksEmployeeSetup, QuickBooksTimesheetsSetupInput & { callback?: () => void }, AppThunkConfig>(
  'quickbooks/saveQuickBooksTimesheetsMapping',
    async x => {
      const response = await saveQuickBooksEmployeeMappingMutation(x.mappings, x.autoSyncTimesheets);
      if (x.callback) {
        x.callback();
      }
      return response;
    },
  { serializeError: serializeBusinessError }
);

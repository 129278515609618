import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksSyncDay } from '../models/QuickBooksSyncDay';

export async function syncTimesheetsWithQuickbooksMutation(day: string): Promise<QuickBooksSyncDay> {
  const result = await getQuickBooksGraphQlClient().query<{
    syncTimesheetsWithQuickbooks: QuickBooksSyncDay;
  }>(
    `
      mutation($day: String!){
        syncTimesheetsWithQuickbooks(day: $day) {
          id
          day
          syncArea
          syncStatus
        }
      }
    `,
    { day }
  );
  return result.syncTimesheetsWithQuickbooks;
}

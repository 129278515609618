import { ReportParameterWithValue } from './reportParameterValuesHelper';
import {
  CreditBatchReportParameterValue,
  DateReportParameterValue,
  FoodBevZReportReportParameterValue,
  PtabsZReportReportParameterValue,
  GameReportParameterValue,
  SingleChoiceListReportParameterValue,
  GameWeightsReportParameterValue,
  DateRangeReportParameterValue,
  DateTimeRangeReportParameterValue,
  YearReportParameterValue,
  YearMonthReportParameterValue,
  YearQuarterReportParameterValue,
  GenerationTimeReportParameterValue,
  TillReportParameterValue,
} from '../../../store/reports/models/reportParameterValue';
import { ReportParameterInput } from '../../../store/reports/models/reportGenerationRequest';

export function getReportParametersForRequest(
  parameters: ReportParameterWithValue[],
): ReportParameterInput[] {
  const result: ReportParameterInput[] = [];
  const setParameter = (name: string, value: string) => result.push({ name, value });
  for (const { parameter, value } of parameters) {
    switch (parameter.type) {
      case 'credit-batch': {
        const typedValue = value as CreditBatchReportParameterValue;
        if (typedValue.batch != null) {
          setParameter(parameter.batchIdParameterName, typedValue.batch.batchId ?? '');
          setParameter(parameter.moduleParameterName, typedValue.batch.module);
          setParameter(parameter.merchantIdParameterName, typedValue.batch.merchantId);
        }
        break;
      }
      case 'date': {
        const typedValue = value as DateReportParameterValue;
        if (typedValue.date != null) {
          setParameter(parameter.parameterName, typedValue.date.toFormat('yyyy-MM-dd'));
        }
        break;
      }
      case 'foodbev-z-report': {
        const typedValue = value as FoodBevZReportReportParameterValue;
        if (typedValue.zReport != null) {
          setParameter(parameter.parameterName, typedValue.zReport.reportId);
        }
        break;
      }
      case 'ptabs-z-report': {
        const typedValue = value as PtabsZReportReportParameterValue;
        if (typedValue.zReport != null) {
          setParameter(parameter.parameterName, typedValue.zReport.reportId);
        }
        break;
      }
      case 'game': {
        const typedValue = value as GameReportParameterValue;
        if (typedValue.game != null) {
          setParameter(parameter.parameterName, typedValue.game.gameId);
        }
        break;
      }
      case 'single-choice-list': {
        const typedValue = value as SingleChoiceListReportParameterValue;
        if (typedValue.item != null) {
          setParameter(parameter.parameterName, typedValue.item.key);
        }
        break;
      }
      case 'game-weights': {
        const typedValue = value as GameWeightsReportParameterValue;
        if (typedValue.gameWeights != null) {
          const serialized = JSON.stringify(typedValue.gameWeights
            .map(x => ({ id: x.gameDealId, cw: x.weight })));
          setParameter(parameter.parameterName, serialized);
        }
        break;
      }
      case 'date-range': {
        const typedValue = value as DateRangeReportParameterValue;
        if (typedValue.dateFrom != null && typedValue.dateTo != null) {
          setParameter(parameter.dateFromParameterName, typedValue.dateFrom.toFormat('yyyy-MM-dd'));
          setParameter(parameter.dateToParameterName, typedValue.dateTo.toFormat('yyyy-MM-dd'));
        }
        break;
      }
      case 'date-time-range': {
        const typedValue = value as DateTimeRangeReportParameterValue;
        if (typedValue.dateFrom != null && typedValue.dateTo != null) {
          setParameter(parameter.dateFromParameterName, typedValue.dateFrom.toISO({ includeOffset: false }));
          setParameter(parameter.dateToParameterName, typedValue.dateTo.toISO({ includeOffset: false }));
        }
        break;
      }
      case 'year': {
        const typedValue = value as YearReportParameterValue;
        if (typedValue.year != null) {
          setParameter(parameter.yearParameterName, typedValue.year.toFixed(0));
        }
        break;
      }
      case 'year-month': {
        const typedValue = value as YearMonthReportParameterValue;
        if (typedValue.value != null) {
          setParameter(parameter.yearParameterName, typedValue.value.year.toFixed(0));
          setParameter(parameter.monthParameterName, typedValue.value.month.toFixed(0));
        }
        break;
      }
      case 'year-quarter': {
        const typedValue = value as YearQuarterReportParameterValue;
        if (typedValue.value != null) {
          setParameter(parameter.yearParameterName, typedValue.value.year.toFixed(0));
          setParameter(parameter.quarterParameterName, typedValue.value.quarter.toFixed(0));
        }
        break;
      }
      case 'generation-time': {
        const typedValue = value as GenerationTimeReportParameterValue;
        if (typedValue.generationTime != null) {
          setParameter(parameter.parameterName, typedValue.generationTime.toISO({ includeOffset: false }));
        }
        break;
      }
      case 'till': {
        const typedValue = value as TillReportParameterValue;
        if (typedValue.till != null) {
          setParameter(parameter.parameterName, typedValue.till.tillId);
        }
        break;
      }
      default: {
        // tslint:disable-next-line: no-any
        throw new Error(`Unsupported report type parameter: ${(parameter as any).type}`);
      }
    }
  }

  return result;
}

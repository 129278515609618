import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import {
  ReportFormatEnum,
  RequestReportGenerationInput,
  RequestReportGenerationResult,
} from '../models/reportGenerationRequest';
import { getReportsGraphQlClient } from '../../../api/clients/getReportsGraphQlClient';
import { getReportParametersForRequest } from '../../../components/reports/helpers/getReportParametersForRequest';
import { ReportType } from '../models/reportType';
import { ReportParameterWithValue } from '../../../components/reports/helpers/reportParameterValuesHelper';

export type RequestReportGenerationArgs = {
  reportType: ReportType;
  reportParameterValues: ReportParameterWithValue[];
  reportFormat: ReportFormatEnum;
};

const requestReportGenerationMutation = async (input: RequestReportGenerationInput) => {
  const result = await getReportsGraphQlClient()
    .mutate<{ requestReportGeneration: RequestReportGenerationResult }>(
      `mutation($input: RequestReportGenerationInput!) {
        requestReportGeneration(input: $input) { id requestStatus }
      }`,
      { input },
    );

  return result.requestReportGeneration;
};

export const requestReportGeneration = createAsyncThunk<RequestReportGenerationResult, RequestReportGenerationArgs, AppThunkConfig>(
  'reports/requestReportGeneration',
  async (
    { reportType, reportParameterValues, reportFormat },
  ) => {
    const reportParameters = getReportParametersForRequest(reportParameterValues);
    reportParameters.push({ name: 'version', value: reportType.version.toFixed(0) });

    return await requestReportGenerationMutation({
      reportFormat,
      reportTypeId: reportType.reportTypeId,
      reportParameters,
    });
  });

import axios, { AxiosInstance } from 'axios';
import { NetworkError, ServerErrors } from '../common/errors';
import { Auth } from 'aws-amplify';

export let requestDateTime: string | undefined;
const isDevMode = false;

export const setRequestDateTime = (dateTime: string) => {
  requestDateTime = dateTime;
};

export const clearRequestDateTime = () => {
  requestDateTime = undefined;
};

if (isDevMode) {
  // tslint:disable-next-line: no-any
  (window as any).setRequestDateTime = setRequestDateTime;
   // tslint:disable-next-line: no-any
  (window as any).clearRequestDateTime = clearRequestDateTime;
}

const QUERY_TIMEOUT = 10000;
const MUTATION_TIMEOUT = 30000;

interface GraphResponse<TData> {
  errors: { message: string, path: string[] }[];
  data: TData;
}

export type HTTPHeadersItem = {
  key: string;
  value: string;
};

const getToken = async (): Promise<string> => {
  const user = await Auth.currentAuthenticatedUser();
  return user.signInUserSession.idToken.jwtToken;
};

export class GraphQlClient {
  private axios: AxiosInstance;

  constructor(url: string, httpHeaders?: HTTPHeadersItem[] | null) {
    this.axios = axios.create({
      baseURL: `${url}/graphql`,
      headers: [],
    });
    this.axios.interceptors.request.use(
      async config => {
        config.headers.authorization = `Bearer ${await getToken()}`;

        if (requestDateTime) {
          config.headers['x-requestdatetime'] = requestDateTime;
        } else if (config.headers['x-requestdatetime']) {
          delete config.headers['x-requestdatetime'];
        }

        if (typeof httpHeaders !== 'undefined' && httpHeaders !== null) {
          httpHeaders.forEach(header => {
            config.headers[header.key] = header.value;
          });
        }

        return config;
      },
      error => Promise.reject(error)
    );
  }

  async query<T>(query: string, variables?: object, timeoutInMs = QUERY_TIMEOUT): Promise<T> {
    return await this.send<T>({ query, variables }, timeoutInMs);
  }

  async mutate<T>(query: string, variables?: object, timeoutInMs = MUTATION_TIMEOUT): Promise<T> {
    return await this.send<T>({ query, variables }, timeoutInMs);
  }

  // tslint:disable-next-line: no-any
  private async send<TResult>(data: any, timeoutInMs: number): Promise<TResult> {
    try {
      const response = await this.axios.post<GraphResponse<TResult>>('', data, { timeout: timeoutInMs });
      const body = response.data;
      if (body.errors != null) {
        throw new ServerErrors(body.errors);
      }
      if (!body || !body.data) {
        throw new Error('Received malformed response.');
      }
      return body.data;
    } catch (err) {
      if (err.message === 'Network Error' || err.code === 'ECONNABORTED') {
        throw new NetworkError('Failed to communicate with server.');
      }

      if (err.response && err.response.status === 500) {
        // tslint:disable-next-line: no-any
        const body = err.response.data as GraphResponse<TResult>;
        if (!body || !body.errors) {
          throw new Error('Received malformed response.');
        }

        throw new ServerErrors(body.errors);
      }

      throw err;
    }
  }
}

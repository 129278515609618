import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksIntegrationStatus } from '../models/QuickBooksIntegrationStatus';
import { QuickBooksSyncStatusInput } from '../models/QuickBooksSyncStatusInput';

export async function setQuickBooksSyncStatusMutation(input: QuickBooksSyncStatusInput): Promise<QuickBooksIntegrationStatus> {
  const result = await getQuickBooksGraphQlClient().query<{ setQuickBooksSyncStatus: QuickBooksIntegrationStatus }>(
    `
      mutation($input: QuickBooksSyncStatusInput!){
        setQuickBooksSyncStatus(input: $input) {
          posOrgId
          isConnected
          isSyncEnabled
          autoSyncTimesheets
          autoSyncSales
        }
      }
    `, { input });
  return result.setQuickBooksSyncStatus;
}

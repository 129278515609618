import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksIntegrationStatus } from '../models/QuickBooksIntegrationStatus';
import { QuickBookConnectInput } from '../models/QuickBookConnectInput';

export async function connectToQuickBooksMutation(input: QuickBookConnectInput): Promise<QuickBooksIntegrationStatus> {
  const result = await getQuickBooksGraphQlClient().query<{ connectToQuickBooks: QuickBooksIntegrationStatus }>(
    `
      mutation($input: QuickBookConnectInput!){
        connectToQuickBooks(input: $input) {
          posOrgId
          isConnected
          isSyncEnabled
          autoSyncTimesheets
          autoSyncSales
        }
      }
    `, { input });
  return result.connectToQuickBooks;
}

import { Member } from './models/member';
import { createFetched, Fetched } from '../../api/common/fetched';
import { FailureData } from '../../api/common/failure';
import { MembersImportResult } from './models/membersImportResult';

export interface MemberState {
  members: Fetched<Member[], string>;
  member: Fetched<Member, string>;
  editOperation: Fetched<void, FailureData<string>>;
  importResult: Fetched<MembersImportResult, FailureData<string>>;
  deleteMembers: Fetched<MembersImportResult, FailureData<string>>;
  bulkAssignMembersToGroup: Fetched<MembersImportResult, FailureData<string>>;
  bulkAssignMembersToNewGroup: Fetched<MembersImportResult, FailureData<string>>;
  getSendSmsMembersCount: Fetched<number, FailureData<string>>;
}

export const initialState: MemberState = {
  members: createFetched(),
  member: createFetched(),
  editOperation: createFetched(),
  importResult: createFetched(),
  deleteMembers: createFetched(),
  bulkAssignMembersToGroup: createFetched(),
  bulkAssignMembersToNewGroup: createFetched(),
  getSendSmsMembersCount : createFetched()
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { del, get, post, put } from '../../api/clients/RestClient';
import { addFetchedCases } from '../../api/common/fetched';
import { initialState } from './reward.state';

export const getRewards = createAsyncThunk(
  'reward/getRewards',
  async (
    { orgId }: { orgId: string }
  ) => {
    // tslint:disable-next-line:no-any
    const response = await get<any>('/rewards/' + orgId);
    return response.rewards;
  });

export const getReward = createAsyncThunk(
  'reward/getReward',
  async (
    { orgId, id }: { orgId: string, id: string }
  ) => {
    // tslint:disable-next-line:no-any
    const response = await get<any>('/rewards/' + orgId + '/' + id);
    return response;
  });

export const deleteReward = createAsyncThunk(
  'reward/deleteReward',
  async (
    { orgId, id }: { orgId: string, id: string }
  ) => {
    // tslint:disable-next-line:no-any
    await del<any>('/rewards/' + orgId + '/' + id);
  });

export const createReward = createAsyncThunk(
  'reward/createReward',
  async (
    { orgId, customerContent, cost }: { orgId: string, customerContent: string, cost: number }
  ) => {
    // tslint:disable-next-line:no-any
    await post<any>('/rewards/' + orgId, { customerContent, cost, cashierContent: customerContent });
  });

export const updateReward = createAsyncThunk(
  'reward/updateReward',
  async (
    { orgId, customerContent, cost, id }: { orgId: string, customerContent: string, cost: number, id: string }
  ) => {
    // tslint:disable-next-line:no-any
    await put<any>('/rewards/' + orgId + '/' + id, { customerContent, cost });
  });

export const rewardSlice = createSlice({
  name: 'reward',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(
      builder, getRewards,
      (state, fetched) => state.rewards = fetched);
    addFetchedCases(
      builder, getReward,
      (state, fetched) => state.getReward = fetched);
    addFetchedCases(
      builder, deleteReward,
      (state, fetched) => state.deleteReward = fetched);
    addFetchedCases(
      builder, createReward,
      (state, fetched) => state.createReward = fetched);
    addFetchedCases(
      builder, updateReward,
      (state, fetched) => state.updateReward = fetched);
  },
});

import { createFetched, Fetched } from '../../api/common/fetched';
import Appcode from './models/appcode';
import DeviceInfo from './models/deviceInfo';

export interface DeviceState {
  devices: Fetched<DeviceInfo[], string>;
  updateDevice: Fetched<void, string>;
  getDeviceByAppcode: Fetched<Appcode, string>;
  registerCognitoUser: Fetched<void, string>;
}

export const initialState: DeviceState = {
  devices: createFetched(),
  updateDevice: createFetched(),
  getDeviceByAppcode: createFetched(),
  registerCognitoUser: createFetched(),
};

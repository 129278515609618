import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksSalesSetup } from '../models/QuickBooksSalesSetup';

export const getSaleCategoriesAndAccountsQuery = async (): Promise<Pick<QuickBooksSalesSetup, 'posSaleCategories' | 'quickBooksAccounts'>> =>
  await getQuickBooksGraphQlClient().query<Pick<QuickBooksSalesSetup, 'posSaleCategories' | 'quickBooksAccounts'>>(
    `
      query {
        posSaleCategories {
          id
          type
          name
        }
        quickBooksAccounts {
          id
          name
        }
      }
    `
  );

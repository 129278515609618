import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksTimesheetsSetup } from '../models/QuickBooksTimesheetsSetup';

export const getEmployeesQuery = async (): Promise<Pick<QuickBooksTimesheetsSetup, 'posEmployees' | 'quickBooksEmployees'>> =>
  await getQuickBooksGraphQlClient().query<Pick<QuickBooksTimesheetsSetup, 'posEmployees' | 'quickBooksEmployees'>>(
    `
      query {
        quickBooksEmployees {
          id
          name
        }
        posEmployees {
          id
          name
        }
      }
    `
  );

import { Org } from './models/org';
import { createFetched, Fetched } from '../../api/common/fetched';

export interface OrgState {
  userOrgs: Fetched<Readonly<Org[]>, string>;
  selectedOrg: Org | null;
  getOrg: Fetched<Readonly<Org>, string>;
  reloadCurrentOrg: Fetched<void, string>;
  updateOrg: Fetched<Readonly<Org>, string>;
}

export const initialState: OrgState = {
  userOrgs: createFetched(),
  reloadCurrentOrg: createFetched(),
  getOrg: createFetched(),
  selectedOrg: null,
  updateOrg: createFetched()
};

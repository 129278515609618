import memoizeOne from 'memoize-one';
import { GraphQlClient, HTTPHeadersItem } from './GraphQlClient';

let getConfig: () => ({ url: string; httpHeaders: HTTPHeadersItem[] | null } | undefined) =
  () => undefined;

export function initializeReportsGraphQlClient(
  configResolver: typeof getConfig
) {
  getConfig = configResolver;
}

const clientMemo = memoizeOne(
  (url: string, httpHeaders: HTTPHeadersItem[] | null) => new GraphQlClient(url, httpHeaders));

export function getReportsGraphQlClient() {
  const config = getConfig();
  if (!config) {
    throw new Error('GraphQlClient connection is not configured.');
  }

  return clientMemo(config.url, config.httpHeaders);
}

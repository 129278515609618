import { getReportsGraphQlClient } from '../../../api/clients/getReportsGraphQlClient';
import { ReportTypeHeader } from '../models/reportTypeHeader';

export async function availableReportTypesQuery(supportedReportsApiVersion: number): Promise<ReportTypeHeader[]> {
  const result = await getReportsGraphQlClient().query<{ availableReportTypes: ReportTypeHeader[] }>(
    `
    query($supportedReportsApiVersion: Int!) {
      availableReportTypes(supportedReportsApiVersion: $supportedReportsApiVersion) {
        reportTypeId
        version
        module
        group
        title
        isReceipt
        requiredCapabilities
      }
    }
    `,
    {
      supportedReportsApiVersion,
    }
  );

  return result.availableReportTypes;
}

import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksSyncLog } from '../models/QuickBooksSyncLog';

export async function getQuickBooksSyncLogsQuery(quickbooksSyncDayId: string): Promise<QuickBooksSyncLog[]> {
  const result = await getQuickBooksGraphQlClient().query<{
    latestSyncLogs: QuickBooksSyncLog[];
  }>(
    `
      query (
         $quickbooksSyncDayId: String!
        ) {
        latestSyncLogs (
           quickbooksSyncDayId: $quickbooksSyncDayId
        ) {
          id
          type
          message
          details
          createdDate
        }
      }
    `,
    {
      quickbooksSyncDayId
    }
  );
  return result.latestSyncLogs;
}

import Autopilot from './models/autopilot';
import { createFetched, Fetched } from '../../api/common/fetched';
import { FailureData } from '../../api/common/failure';
import { AutopilotDetails } from './models/autopilotDetails';

export interface AutopilotState {
  autopilots: Fetched<Autopilot[], string>;
  autopilot: Fetched<AutopilotDetails, string>;
  updateOperation: Fetched<void, FailureData<string>>;
}

export const initialState: AutopilotState = {
  autopilots: createFetched(),
  autopilot: createFetched(),
  updateOperation: createFetched(),
};

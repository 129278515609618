import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { QuickBooksIntegrationStatus } from '../models/QuickBooksIntegrationStatus';
import { serializeBusinessError } from '../../../api/common/fetched';
import { getQuickBooksIntegrationStatus } from './getQuickBooksIntegrationStatus';
import { disconnectFromQuickBooksMutation } from '../queries/disconnectFromQuickBooksMutation';

export const disconnectFromQuickBooks = createAsyncThunk<QuickBooksIntegrationStatus, undefined, AppThunkConfig>(
  'quickbooks/disconnectFromQuickBooks',
  async (_, { dispatch }) => {
    const result = await disconnectFromQuickBooksMutation();
    if (result) {
      dispatch(getQuickBooksIntegrationStatus());
    }
    return result;
  },
  {
    serializeError: serializeBusinessError,
  });

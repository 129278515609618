import { createFetched, Fetched } from '../../api/common/fetched';
import { Target } from './models/target';

export interface TargetState {
  groups: Fetched<Target[], string>;
  deleteGroup: Fetched<void, string>;
  createGroup: Fetched<void, string>;
  updateCustomGroup: Fetched<void, string>;
  updateSystemGroup: Fetched<void, string>;
}

export const initialState: TargetState = {
  groups: createFetched(),
  deleteGroup: createFetched(),
  createGroup: createFetched(),
  updateCustomGroup: createFetched(),
  updateSystemGroup: createFetched(),
};

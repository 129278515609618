
export interface GameInfo {
  id: string;
  gameName: string;
  invoiceDate: string;
  formNumber: string;
  ticketCost: number;

  serialNo: string;
  ticketCount: number;
  topPrizeCount: number | null;
  topPrizeAmount: number | null;
  stateId: string | null;
}

export interface GameSort {
  sortField: SortField;
  sortOrder: SortOrder;
}

export type GameState = 'Returned' | 'InStock' | 'InPlay' | 'Pulled';
export type SortOrder = 'Asc' | 'Desc';
export type SortField = 'GameName' | 'FormNo' | 'SerialNo' | 'StateId';

export const GamesDefaultPageSize = 20;

export interface GamesPagination {
  skip: number;
  take: number;
  pageNumber: number;
}

export interface GamesResult {
  games: GameInfo[];
  sort: GameSort;
  pagination: GamesPagination;
}

import { RootState } from '../../store/root.reducer';
import { BusinessError, NetworkError, ServerErrors } from './errors';
import { FailureCode, FailureData, FailureReason } from './failure';

// tslint:disable-next-line:no-any
export const processError = (error: any) => {
  if (error.response) {
    const isBusinessError = error.response.data.type === 'BusinessError'
        || error.response.status < 500;
    if (isBusinessError) {
      throw new BusinessError([{
        code: error.response.status,
        additionalData: error.response.data
      }
      ]);
    } else {
      if (error.response.data) {
        const parsedErrors = parseServerErrorResponse(error.response.data);
        throw new ServerErrors(parsedErrors);
      } else {
        throw new ServerErrors([{
          code: error.response.status,
          additionalData: error.response.data
        }]);
      }
    }

  } else if (error.request) {
    throw new NetworkError('Failed to communicate with server.');

  } else {
    throw new ServerErrors([{
      code: error.message,
      additionalData: { message: error.message }
    }
    ]);
  }
};

// tslint:disable-next-line:no-any
const parseServerErrorResponse = (responseData: any) => {
  if (responseData.errors) {
    // tslint:disable-next-line:no-any
    const parsedErrors = responseData.errors.map((error: any) => ({
      code: error.path ? error.path.join('.') : '',
      additionalData: {
        message: error.message,
        locations: error.locations
      }
    }));

    return parsedErrors;
  }

  return [];
};

const ErrorTypeMessage = {
  [FailureReason.CommunicationError]: 'Apologies, there are some difficulties with server/network communication.',
  [FailureReason.BusinessError]: 'Apologies, there are some problems with server reply.',
  [FailureReason.UnknownError]: 'Unknown issue',
};

const mapFailureMessage = (failureData: FailureData, rootState: RootState | undefined): FailureData => {
  const result = {
    ...failureData,
    additionalData: {
      ...failureData?.additionalData
    }
  };

  switch (failureData.code) {
    /*case FailureCode.MemberLimitExceeded:
      const memberLimitText = rootState?.org?.currentOrg?.memberLimit != null
        ? `(${rootState.org.currentOrg.memberLimit}) `
        : '';
      result.additionalData.message = `You have reached the maximum amount of members ${memberLimitText}for your current plan. Please consider upgrading your license.`;
      return result;
    case FailureCode.KioskLimitExceeded:
      const kioskLimitText = rootState?.org?.currentOrg?.kioskLimit != null
        ? `(${rootState.org.currentOrg.kioskLimit}) `
        : '';
      result.additionalData.message = `You have reached the maximum amount of kiosks ${kioskLimitText}for your current plan. If you are replacing an existing kiosk, please deactivate the old one first. If you are adding an additional kiosk, please consider upgrading your license.`;
      return result;*/
    case FailureCode.StripeUnavailable:
      result.additionalData.message = 'Stripe is unavailable. Cannot procees with stripe payment.';
      return result;
    case FailureCode.StripeError:
      result.additionalData.message = failureData.additionalData;
      return result;
  }

  // should be replaced with error code
  if (failureData.additionalData.message?.includes('Code does not exist')) {
    result.additionalData.message = 'Unfortunately, your access code has expired. Please restart Kiosk application to get a new one.';
    return result;
  }
  // should be replaced with error code
  if (failureData.additionalData.message?.includes('Name already used')) {
    result.additionalData.message = 'The name has already been used';
    return result;
  }

  if (failureData.additionalData.errorCode === 'AccessCodeInvalid') {
    result.additionalData.message = 'Provided code is either invalid or expired. Please try again';
    return result;
  }

  if (failureData.additionalData.message == null) {
    result.additionalData.message = ErrorTypeMessage[failureData.reason];
  }
  return result;
};

export const processFailureReason = (error: unknown, rootState: RootState | undefined): FailureData => {
  const failureData: FailureData = {
    reason: FailureReason.UnknownError,
    code: ''
  };
  if (error instanceof NetworkError) {
    failureData.reason = FailureReason.CommunicationError;
  } else if (error instanceof ServerErrors || error instanceof BusinessError) {
    failureData.reason = FailureReason.BusinessError;
    failureData.code = error.errors[0].code;
    failureData.additionalData = error.errors[0].additionalData;
    return mapFailureMessage(failureData, rootState);
  }

  return failureData;
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { put } from '../../api/clients/RestClient';
import { addFetchedCases, LoadState } from '../../api/common/fetched';
import { Org } from './models/org';
import { getOrgFromServer, getOrgsFromServer } from './org.actions';
import { initialState } from './org.state';
import { reloadCurrentOrgCaseSetter } from './cases/reloadCurrentOrgCaseSetter';
import { getVenueSettings } from '../config/config.slice';
import { initializeReportsGraphQlClient } from '../../api/clients/getReportsGraphQlClient';
import { initializeQuickBooksGraphQlClient } from '../../api/clients/getQuickBooksClient';

export const SelectedOrgLocalStorageKey = 'TabKing.SelectedOrg';

export const getUserOrgs = createAsyncThunk(
  'org/getUserOrgs',
  async () => {
    const orgs = await getOrgsFromServer();
    return orgs.sort((org1, org2) => org1.name.localeCompare(org2.name));
  });

export const updateOrg = createAsyncThunk(
  'org/updateOrg',
  async (
    {
      orgId,
      name,
      testPhone,
      smsShortCode,
      earnPointAmount,
      pointsPerCheckin,
      checkinInterval,
      transparentImageBackground,
      uploadedImageEncoded,
      image
    }: { orgId: string; name: string; testPhone: number; smsShortCode: string; earnPointAmount: number; pointsPerCheckin: number; checkinInterval: number, transparentImageBackground: boolean, uploadedImageEncoded: string, image: string },
  ) => {
    if (!uploadedImageEncoded) {
      await put<void>('/orgs/' + orgId, {
        name,
        testPhone,
        smsShortCode,
        image,
        earnPointPerAmount: 1, // api depricated
        successRateVisits: 0, // future use
        earnPointAmount,
        pointsPerCheckin,
        checkinInterval,
        transparentImageBackground
      });
    } else {
      const imageContentType = extractType(uploadedImageEncoded);
      const base64 = trimPrefix(uploadedImageEncoded);
      await put<void>('/orgs/' + orgId, {
        name,
        testPhone,
        smsShortCode,
        earnPointPerAmount: 1, // api depricated
        successRateVisits: 0, // future use
        earnPointAmount,
        pointsPerCheckin,
        checkinInterval,
        imageContentType,
        imageBase64: base64,
        transparentImageBackground
      });
    }

    const org = await getOrgFromServer(orgId);
    return org;
  }
);

export const getOrg = createAsyncThunk(
  'org/getOrg',
  async (
    { id }: { id: string },
  ) => {
    const serverOrg = await getOrgFromServer(id);
    return serverOrg;
  });

export const reloadCurrentOrg = createAsyncThunk(
  'org/reloadCurrentOrg',
  async (id: string) => {
    return await getOrgFromServer(id);
  });

export const selectOrg = createAsyncThunk(
  'org/selectOrg',
  async (
    { org }: { org: Org },
    { dispatch },
  ) => {
    try {
      localStorage.setItem(SelectedOrgLocalStorageKey, org.id);
      initializeReportsGraphQlClient(() => ({
        url: process.env.REACT_APP_BASE_CLOUD_REPORTS_API_URL,
        httpHeaders: [{ key: 'X-OrgId', value: org.posOrgId }],
      }));
      initializeQuickBooksGraphQlClient(() => ({
        url: process.env.REACT_APP_BASE_QUICK_BOOKS_API_URL,
        httpHeaders: [{ key: 'X-OrgId', value: org.posOrgId }],
      }));
      dispatch(getVenueSettings());
      return org;
    } catch (e) {
      console.error(e);
      return org;
    }
  });

export const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(
      builder, getUserOrgs,
      (state, fetched) => state.userOrgs = fetched,
    );
    addFetchedCases(builder, reloadCurrentOrg, reloadCurrentOrgCaseSetter);
    addFetchedCases(
      builder, getOrg,
      (state, fetched) => state.getOrg = fetched,
    );
    addFetchedCases(
      builder, updateOrg,
      (state, fetched) => {
        state.updateOrg = fetched;
        if (fetched.data) {
          if (state.userOrgs.loadState === LoadState.Success) {
            const ix = state.userOrgs.data.findIndex(org => org.id === fetched.data.id);
            if (ix > -1) {
              state.userOrgs.data[ix] = fetched.data;
            }
          }
          state.selectedOrg = fetched.data;
        }
      }
    );
    builder.addCase(selectOrg.fulfilled, (state, action) => {
      if (action.payload && state.userOrgs.loadState === LoadState.Success) {
        const ix = state.userOrgs.data.findIndex(org => org.id === action.payload.id);
        if (ix > -1) {
          state.userOrgs.data[ix] = action.payload;
        }
      }
      state.selectedOrg = action.payload;
    });
  },

});

const extractType = (file: string) => {
  const str = file.substring(0, 20);
  const myRe = new RegExp('(?<=:)(.*?)(?=;)');
  const regExpResult = myRe.exec(str);
  return regExpResult[0];
};

const trimPrefix = (file: string) => {
  return file.substring(file.indexOf(',') + 1);
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { serializeBusinessError } from '../../../api/common/fetched';
import { syncSalesWithQuickbooksMutation } from '../queries/syncSalesWithQuickbooksMutation';
import { QuickBooksSyncTimesheetsInput } from '../models/QuickBooksSyncTimesheetsInput';
import { QuickBooksSyncDay } from '../models/QuickBooksSyncDay';

export const syncSalesWithQuickbooks = createAsyncThunk<QuickBooksSyncDay, QuickBooksSyncTimesheetsInput, AppThunkConfig>(
  'quickbooks/syncSalesWithQuickbooks',
  async input => {
    const result = await syncSalesWithQuickbooksMutation(input.day);
    return result;
  },
  {
    serializeError: serializeBusinessError,
  }
);

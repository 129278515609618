import { createFetched, Fetched } from '../../api/common/fetched';
import { QuickBooksIntegrationStatus } from './models/QuickBooksIntegrationStatus';
import { FailureData } from '../../api/common/failure';
import { QuickBooksAuthorizationData } from './models/QuickBooksAuthorizationData';
import { QuickBooksEmployeeSetup, QuickBooksTimesheetsSetup } from './models/QuickBooksTimesheetsSetup';
import { QuickBooksSaleCategorySetup, QuickBooksSalesSetup } from './models/QuickBooksSalesSetup';
import { QuickBooksSyncDay } from './models/QuickBooksSyncDay';
import { QuickBooksSyncLog } from './models/QuickBooksSyncLog';

export interface QuickBooksState {
  quickBooksIntegrationStatus: Fetched<QuickBooksIntegrationStatus, FailureData<string>>;
  connectToQuickBooks: Fetched<QuickBooksIntegrationStatus, FailureData<string>>;
  quickBooksAuthorizationData: Fetched<QuickBooksAuthorizationData, FailureData<string>>;
  quickBooksTimesheetsSetup: Fetched<QuickBooksTimesheetsSetup, FailureData<string>>;
  getEmployeesOperation: Fetched<
    Pick<QuickBooksTimesheetsSetup, 'posEmployees' | 'quickBooksEmployees'>,
    FailureData<string>
  >;
  saveEmployeeMappingOperation: Fetched<QuickBooksEmployeeSetup, FailureData<string>>;
  quickBooksSalesSetup: Fetched<QuickBooksSalesSetup, FailureData<string>>;
  getSaleCategoriesAndAccountsOperation: Fetched<
    Pick<QuickBooksSalesSetup, 'posSaleCategories' | 'quickBooksAccounts'>,
    FailureData<string>
  >;
  saveQuickBooksSaleCategoryMapping: Fetched<QuickBooksSaleCategorySetup, FailureData<string>>;
  setQuickBooksSyncStatus: Fetched<QuickBooksIntegrationStatus, FailureData<string>>;
  getQuickBooksSyncDays: Fetched<QuickBooksSyncDay[], FailureData<string>>;
  syncTimesheetsWithQuickbooks: Fetched<QuickBooksSyncDay, FailureData<string>>;
  syncSalesWithQuickbooks: Fetched<QuickBooksSyncDay, FailureData<string>>;
  latestSyncLogs: Fetched<QuickBooksSyncLog[], FailureData<string>>;
  disconnectFromQuickBooks: Fetched<QuickBooksIntegrationStatus, FailureData<string>>;
}

export const initialState: QuickBooksState = {
  quickBooksIntegrationStatus: createFetched(),
  connectToQuickBooks: createFetched(),
  quickBooksAuthorizationData: createFetched(),
  quickBooksTimesheetsSetup: createFetched(),
  saveEmployeeMappingOperation: createFetched(),
  getEmployeesOperation: createFetched(),
  quickBooksSalesSetup: createFetched(),
  getSaleCategoriesAndAccountsOperation: createFetched(),
  saveQuickBooksSaleCategoryMapping: createFetched(),
  setQuickBooksSyncStatus: createFetched(),
  getQuickBooksSyncDays: createFetched(),
  syncTimesheetsWithQuickbooks: createFetched(),
  syncSalesWithQuickbooks: createFetched(),
  latestSyncLogs: createFetched(),
  disconnectFromQuickBooks: createFetched(),
};

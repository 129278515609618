import { createFetched, Fetched } from '../../api/common/fetched';
import { Promotion, PromotionResponse } from './models/promotion';

export interface PromotionState {
  promotions: Fetched<Promotion[], string>;
  createPromotion: Fetched<PromotionResponse, string>;
  updatePromotionDraft: Fetched<PromotionResponse, string>;
  deletePromotion: Fetched<string, string>;
  getPromotion: Fetched<Promotion, string>;
  cancelPromotion: Fetched<string, string>;
  savePrmotionDraft: Fetched<PromotionResponse, string>;
}

export const initialState: PromotionState = {
  promotions: createFetched(),
  createPromotion: createFetched(),
  updatePromotionDraft: createFetched(),
  deletePromotion: createFetched(),
  getPromotion: createFetched(),
  cancelPromotion: createFetched(),
  savePrmotionDraft: createFetched()
};

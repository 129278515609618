import { createFetched, Fetched } from '../../api/common/fetched';
import { Campaign } from './models/campaign';
import PromotionStats from './models/promotion.stats';
import RewardStats from './models/rewardStats';

export type ResolutionType = 'day' | 'week' | 'month' | 'year';

export interface CruiseControlState {
  campaigns: Fetched<Campaign[], string>;
  rewardStats: Fetched<RewardStats[], string>;
  promotionStats: Fetched<PromotionStats[], string>;
}

export const initialState: CruiseControlState = {
  campaigns: createFetched(),
  rewardStats: createFetched(),
  promotionStats: createFetched(),
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { serializeBusinessError } from '../../../api/common/fetched';
import { getQuickBooksSalesSetupQuery } from '../queries/getQuickBooksSalesSetupQuery';
import { QuickBooksSalesSetup } from '../models/QuickBooksSalesSetup';

export const getQuickBooksSalesSetup = createAsyncThunk<QuickBooksSalesSetup, undefined, AppThunkConfig>(
  'quickbooks/getQuickBooksSalesSetup',
  async () => await getQuickBooksSalesSetupQuery(),
  {
    serializeError: serializeBusinessError,
  }
);

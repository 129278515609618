import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { serializeBusinessError } from '../../../api/common/fetched';
import { getQuickBooksTimesheetsSetupQuery } from '../queries/getQuickBooksTimesheetsSetupQuery';
import { QuickBooksTimesheetsSetup } from '../models/QuickBooksTimesheetsSetup';

export const getTimesheetsSetup = createAsyncThunk<QuickBooksTimesheetsSetup, undefined, AppThunkConfig>(
  'quickbooks/getTimesheetsSetup',
  async () => await getQuickBooksTimesheetsSetupQuery(),
  {
    serializeError: serializeBusinessError,
  }
);

import { Dispatch } from 'redux';
import { FailureData, FailureReason } from './failure';
import { ActionReducerMapBuilder, AsyncThunk, Draft, SerializedError } from '@reduxjs/toolkit';
import { BusinessError, NetworkError, ServerErrors } from './errors';

export enum LoadState {
  NotRequested = 'NotRequested',
  InProgress = 'InProgress',
  Success = 'Success',
  Failed = 'Failed'
}

export type Fetched<TData, TFailureReason = FailureData>
  = IFetchedNotRequested
  | IFetchedInProgress<TData>
  | IFetchedSuccess<TData>
  | IFetchedFailed<TFailureReason>;

export interface IFetchedNotRequested {
  loadState: LoadState.NotRequested;
  data?: undefined;
  failureReason?: undefined;
  lastUpdate: Date;
}

export interface IFetchedInProgress<TData> {
  loadState: LoadState.InProgress;
  data?: TData;
  failureReason?: undefined;
  lastUpdate: Date;
}

export interface IFetchedSuccess<TData> {
  loadState: LoadState.Success;
  data: TData;
  failureReason?: undefined;
  lastUpdate: Date;
}

export interface IFetchedFailed<TFailureReason> {
  loadState: LoadState.Failed;
  data?: null;
  failureReason: TFailureReason;
  lastUpdate: Date;
}

export function isLoadPending<TData>(
  fetched: Fetched<TData, unknown>,
): fetched is IFetchedNotRequested | IFetchedInProgress<TData> {
  return fetched.loadState === LoadState.NotRequested || fetched.loadState === LoadState.InProgress;
}

export interface IFetchedUpdated<TActionType, TData, TFailureReason = FailureData> {
  type: TActionType;
  fetched: Fetched<TData, TFailureReason>;
  silentReload: boolean;
}

export interface IFetchedPending {
  loadState: LoadState.NotRequested | LoadState.InProgress;
  data: null;
  failureReason: null;
  lastUpdate: Date;
}

export const createFetchedInProgress = (): IFetchedPending => ({
  loadState: LoadState.InProgress,
  data: null,
  failureReason: null,
  lastUpdate: new Date()
});

export const createFetchedFailed = <TFailureReason = FailureReason>(failureReason: TFailureReason): IFetchedFailed<TFailureReason> => ({
  loadState: LoadState.Failed,
  data: null,
  failureReason,
  lastUpdate: new Date()
});

export const createFetched = (): IFetchedNotRequested => ({
  loadState: LoadState.NotRequested,
  lastUpdate: new Date(),
});

export const createFetchedSuccess = <TData>(data: TData): IFetchedSuccess<TData> => ({
  loadState: LoadState.Success,
  data,
  lastUpdate: new Date(),
});

export function updateFetched<TActionType, TData, TFailureReason>(
  current: Fetched<TData, TFailureReason>,
  action: IFetchedUpdated<TActionType, TData, TFailureReason>
): Fetched<TData, TFailureReason> {
  const result = { ...action.fetched };
  if (action.silentReload
    && (result.loadState === LoadState.InProgress || result.loadState === LoadState.Failed)) {
    result.data = current.data || result.data;
  }
  return result;
}

export function resetFetched<TActionType, TData = void, TFailureReason = FailureData>(
  actionType: TActionType,
  dispatch: Dispatch<IFetchedUpdated<TActionType, TData, TFailureReason>>,
) {
  dispatch({
    type: actionType,
    fetched: createFetched(),
    silentReload: false,
  });
}

export function addFetchedCasesWithFailureReason<TState, TFailureReason, Returned, ThunkArg = void, ThunkApiConfig = {}>(
  builder: ActionReducerMapBuilder<TState>,
  actionCreator: AsyncThunk<Returned, ThunkArg, ThunkApiConfig>,
  getFailureReason: (action: RejectedPayload) => TFailureReason,
  setter: (state: Draft<TState>, fetched: Fetched<Returned, TFailureReason>) => void,
) {
  builder
    .addCase(actionCreator.pending, (state, action) => {
      setter(state, createFetchedInProgress());
    })
    .addCase(actionCreator.fulfilled, (state, action) => {
      setter(state, createFetchedSuccess(action.payload));
    })
    .addCase(actionCreator.rejected, (state, action) => {
      // tslint:disable-next-line:no-any
      setter(state, createFetchedFailed(getFailureReason((action as any) as RejectedPayload)));
    });
}

export function addFetchedCases<TState, Returned, ThunkArg = void, ThunkApiConfig = {}>(
  builder: ActionReducerMapBuilder<TState>,
  actionCreator: AsyncThunk<Returned, ThunkArg, ThunkApiConfig>,
  setter: (state: Draft<TState>, fetched: Fetched<Returned, string>) => void,
) {
  addFetchedCasesWithFailureReason<TState, string, Returned, ThunkArg, ThunkApiConfig>(
    builder, actionCreator,
    action => action.error.message,
    setter);
}

export type RejectedPayload = {
  error: SerializedError;
};

// tslint:disable-next-line:no-any
export function serializeBusinessError(value: any): SerializedError {
  if (typeof value === 'object' && value !== null) {
    if (typeof value.payload === 'string') {
      return { message: String(value.payload) };
    }
    const error = value.errors?.[0];
    const additionalData = error?.additionalData;
    if (additionalData?.message && additionalData?.errorCode) {
      return {
        code: error.code,
        message: additionalData.message,
        name: additionalData.errorCode,
      };
    }
    if (additionalData?.errors) {
      const nestedError = additionalData?.errors?.[0];
      const nestedErrorData = nestedError?.extensions;
      if (nestedErrorData) {
        return {
          code: error.code,
          message: nestedErrorData.userMessage ?? nestedError.message,
          name: nestedErrorData.errorCode,
        };
      }
    }
    if (value instanceof NetworkError) {
      return {
        name: FailureReason.CommunicationError,
        code: FailureReason.CommunicationError,
        message: value.message,
      };
    }

    if (value instanceof ServerErrors || value instanceof BusinessError) {
      if (additionalData) {
        return {
          code: error.code,
          message: additionalData.message,
          name: additionalData.errorCode,
        };
      }
      return {
        name: FailureReason.BusinessError,
        code: value.errors[0].code,
        message: value.errors[0].message,
      };
    }
    if (error?.message || error?.code) {
      return {
        code: error.code,
        message: error.message,
        name: error.code,
      };
    }
    const simpleError: SerializedError = {};
    for (const property of ['name', 'message', 'stack', 'code']) {
      if (typeof value[property] === 'string') {
        simpleError[property] = value[property];
      }
    }
    return simpleError;
  }
  return { message: String(value) };
}

export function rejectedPayloadToStringFailureData(action: RejectedPayload): FailureData<string> {
  return {
    reason: action.error.message,
    additionalData: action.error,
    code: action.error.code,
  };
}

export function rejectedPayloadToFailureData(action: RejectedPayload): FailureData {
  const failureData: FailureData = {
    reason: FailureReason.UnknownError,
    code: null,
    additionalData: {
      message: action.error.message,
    },
  };
  if (action.error.name === FailureReason.CommunicationError) {
    failureData.reason = FailureReason.CommunicationError;
  } else if (action.error.name === FailureReason.BusinessError) {
    failureData.reason = FailureReason.BusinessError;
    failureData.code = action.error.code;
  }
  return failureData;
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { del, get, post, put } from '../../api/clients/RestClient';
import { initialState } from './promotion.state';
import { addFetchedCases } from '../../api/common/fetched';
import { Promotion, PromotionResponse } from './models/promotion';
import { Target } from '../target/models/target';
import { AppThunkConfig } from '../root.reducer';

export const getPromotions = createAsyncThunk(
  'promotion/getPromotions',
  async (
    { orgId }: { orgId: string; }
  ) => {

    const { promos } = await get<{ promos: Promotion[] }>('promos/' + orgId);
    return promos;
  });

export const getPromotion = createAsyncThunk(
  'promotion/getPromotion',
  async (
    { orgId, id }: { orgId: string; id: string }
  ) => {
    const reply = await get<Promotion>('/promos/' + orgId + '/' + id);
    return reply as Promotion;
  });

export type SendNewPromotionInput = {
  orgId: string;
  customerContent: string;
  cashierContent: string;
  startDate: string;
  endDate: string;
  targets: Target[];
  scheduledDate: string;
  estimatedSmsPartsCount: number;
};

export const sendNewPromotion = createAsyncThunk<PromotionResponse, SendNewPromotionInput, AppThunkConfig>(
  'promotion/sendNewPromotion',
  async (
    { orgId, customerContent, cashierContent, startDate, endDate, targets, scheduledDate, estimatedSmsPartsCount },
  ) => {
    const promotionCreate = {
      customerContent,
      cashierContent,
      endDate,
      startDate,
      targets: targets.map(p => p.id),
      estimatedSmsPartsCount,
      scheduledDate
    };

    return await post<PromotionResponse>('/promos/' + orgId, promotionCreate);
  });

export type SendDraftPromotionInput = SendNewPromotionInput & {
  id: string;
};

export const sendDraftPromotion = createAsyncThunk<PromotionResponse, SendDraftPromotionInput, AppThunkConfig>(
  'promotion/sendDraftPromotion',
  async (
    { orgId, customerContent, cashierContent, startDate, endDate, targets, id, scheduledDate, estimatedSmsPartsCount },
  ) => {
    const promotionUpdate = {
      startDate,
      customerContent,
      cashierContent,
      endDate,
      scheduledDate,
      targets: targets.map(p => p.id),
      estimatedSmsPartsCount,
    };
    return  await put<PromotionResponse>('/promos/' + orgId + '/' + id, promotionUpdate);
  });

export type SaveDraftPromotionInput = {
  orgId: string;
  customerContent: string;
  cashierContent: string;
  startDate: string;
  endDate: string;
  targets: Target[];
  estimatedSmsPartsCount: number;
};

export type UpdateDraftPromotionInput = SaveDraftPromotionInput & {
  id: string
};

export const updateDraftPromotion = createAsyncThunk<PromotionResponse, UpdateDraftPromotionInput, AppThunkConfig>(
  'promotion/updateDraftPromotion',
  async (
    { orgId, customerContent, cashierContent, startDate, endDate, targets, id, estimatedSmsPartsCount },
  ) => {
    const promotionUpdate = {
      startDate,
      customerContent,
      cashierContent,
      endDate,
      scheduledDate: null,
      targets: targets.map(p => p.id),
      estimatedSmsPartsCount,
    };
    return await put<PromotionResponse>('/promos/' + orgId + '/' + id, promotionUpdate);
  });

export const saveDraftPromotion = createAsyncThunk<PromotionResponse, SaveDraftPromotionInput, AppThunkConfig>(
  'promotion/saveDraftPromotion',
  async (
    { orgId, customerContent, cashierContent, startDate, endDate, targets, estimatedSmsPartsCount },
  ) => {
    const draftPromotion = {
      startDate,
      customerContent,
      cashierContent,
      endDate,
      scheduledDate: null,
      targets: targets.map(p => p.id),
      estimatedSmsPartsCount,
    };

    return await post<PromotionResponse>('/promos/' + orgId, draftPromotion);
  });

export const deletePromotion = createAsyncThunk(
  'promotion/deletePromotion',
  async (
    { orgId, id }: { orgId: string; id: string; }
  ) => {
    await del<void>('/promos/' + orgId + '/' + id);
    return id;
  });

export const cancelPromotion = createAsyncThunk(
  'promotion/cancelPromotion',
  async (
    { orgId, id }: { orgId: string, id: string }
  ) => {
    await put<void>('/promos/' + orgId + '/' + id, { status: 'cancelled' });
    return id;
  });

export const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(
      builder, getPromotions,
      (state, fetched) => state.promotions = fetched);
    addFetchedCases(
      builder, sendNewPromotion,
      (state, fetched) => state.createPromotion = fetched);
    addFetchedCases(
      builder, updateDraftPromotion,
      (state, fetched) => state.updatePromotionDraft = fetched);
    addFetchedCases(
      builder, deletePromotion,
      (state, fetched) => state.deletePromotion = fetched);
    addFetchedCases(
      builder, getPromotion,
      (state, fetched) => state.getPromotion = fetched);
    addFetchedCases(
      builder, cancelPromotion,
      (state, fetched) => state.cancelPromotion = fetched);
    addFetchedCases(
      builder, saveDraftPromotion,
      (state, fetched) => state.savePrmotionDraft = fetched);
    addFetchedCases(
      builder, sendDraftPromotion,
      (state, fetched) => state.createPromotion = fetched);
  },
});

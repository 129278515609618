import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { QuickBooksIntegrationStatus } from '../models/QuickBooksIntegrationStatus';
import { serializeBusinessError } from '../../../api/common/fetched';
import { QuickBookConnectInput } from '../models/QuickBookConnectInput';
import { connectToQuickBooksMutation } from '../queries/connectToQuickBooksMutation';
import { getQuickBooksIntegrationStatus } from './getQuickBooksIntegrationStatus';

export const connectToQuickBooks = createAsyncThunk<QuickBooksIntegrationStatus, QuickBookConnectInput, AppThunkConfig>(
  'quickbooks/connectToQuickBooks',
  async (input, { dispatch }) => {
    const result = await connectToQuickBooksMutation(input);
    if (result) {
      dispatch(getQuickBooksIntegrationStatus());
    }
    return result;
  },
  {
    serializeError: serializeBusinessError,
  });

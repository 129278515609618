import { getQuickBooksGraphQlClient } from '../../../api/clients/getQuickBooksClient';
import { QuickBooksIntegrationStatus } from '../models/QuickBooksIntegrationStatus';

export async function getQuickBooksIntegrationStatusQuery(): Promise<QuickBooksIntegrationStatus> {
  const result = await getQuickBooksGraphQlClient().query<{ quickBooksIntegrationStatus: QuickBooksIntegrationStatus }>(
    `
      query {
        quickBooksIntegrationStatus {
          posOrgId
          isConnected
          isSyncEnabled
          autoSyncTimesheets
          autoSyncSales
        }
      }
    `);
  return result.quickBooksIntegrationStatus;
}

import { getReportsGraphQlClient } from '../../../api/clients/getReportsGraphQlClient';
import { CardPaymentBatchSummary } from '../models/cardPaymentBatchSummary';
import { ReportModuleEnum } from '../models/reportModuleEnum';

export async function cardBatchesQuery(
  month: string | null,
  year: string | null,
  reportModule: ReportModuleEnum | null,
): Promise<CardPaymentBatchSummary[]> {
  const result = await getReportsGraphQlClient()
    .query<{ cardBatches: CardPaymentBatchSummary[] }>(
      `query($month: String, $year: String, $reportModule: ReportModuleEnum) {
        cardBatches(month: $month, year: $year, reportModule: $reportModule) {
         batchId merchantId openDate closeDate module
        }
      }`, { month, year, reportModule },
    );

  return result.cardBatches;
}

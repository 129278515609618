import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { getQuickBooksSyncDaysQuery } from '../queries/getQuickBooksSyncDaysQuery';
import { serializeBusinessError } from '../../../api/common/fetched';
import { QuickBooksSyncDay } from '../models/QuickBooksSyncDay';
import { QuickBooksSyncDaysArgs } from '../models/QuickBooksSyncDaysArgs';

export const getQuickBooksSyncDays = createAsyncThunk<QuickBooksSyncDay[], QuickBooksSyncDaysArgs, AppThunkConfig>(
  'quickbooks/getQuickBooksSyncDays',
  async args => await getQuickBooksSyncDaysQuery(args), { serializeError: serializeBusinessError }
);

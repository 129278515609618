import { DateObject, DateTime } from 'luxon';
import { useMemo } from 'react';
import { RootState } from '../../../store/root.reducer';
import { useAppSelector } from '../../../utility/hooks/storeHooks';

export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_ZONE = 'America/New_York';

export class ReportDateTimeService {

  constructor(public zone: string) {
  }

  now = () => DateTime.now().setZone(this.zone).setLocale(DEFAULT_LOCALE);

  fromObject = (obj: DateObject) => DateTime.fromObject({ zone: this.zone, locale: DEFAULT_LOCALE, ...obj });

  tryFromISO = (value: string | null | undefined): DateTime | null => {
    if (value == null) return null;

    const parsed = DateTime.fromISO(value, { zone: this.zone, locale: DEFAULT_LOCALE });
    return parsed.isValid ? parsed : null;
  }

}

export const selectReportTimeZone = (state: RootState) => state.config.venueConfig?.timezone ?? DEFAULT_ZONE;

export function useReportDateTimeService() {
  const zone = useAppSelector(selectReportTimeZone);
  const dateTimeService = useMemo(() => new ReportDateTimeService(zone), [zone]);
  return dateTimeService;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { serializeBusinessError } from '../../../api/common/fetched';
import { getEmployeesQuery } from '../queries/getEmployeesQuery';
import { QuickBooksTimesheetsSetup } from '../models/QuickBooksTimesheetsSetup';

export const getEmployees = createAsyncThunk<
  Pick<QuickBooksTimesheetsSetup, 'posEmployees' | 'quickBooksEmployees'>,  undefined,  AppThunkConfig>(
  'quickbooks/getEmployees',
  async () => {
    return await getEmployeesQuery();
  },
  { serializeError: serializeBusinessError }
);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Address, PaymentIntentResult, SetupIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';
import { post } from '../../api/clients/RestClient';
import { addFetchedCases, serializeBusinessError } from '../../api/common/fetched';
import { Notifications } from '../../components/notify/Notifications';
import { notifyError } from '../../components/notify/Notify';
import { reloadCurrentOrg } from '../org/org.slice';
import { RootState } from '../root.reducer';
import { BillingAddress } from './models/billingAddress';
import { VenueSubscription } from './models/venueSubscription';
import { initialState } from './payment.state';
import { ChangeSubscriptionPaymentMethodResult } from './models/ChangeSubscriptionPaymentMethodResult';
import { StripeIntent } from './models/stripeIntent';

export const createPayment = createAsyncThunk(
  'payment/createPayment',
  async (
    {
      stripe,
      elements,
      returnUrl,
      intent,
      billing,
    }: {
      stripe: Stripe | null,
      elements: StripeElements,
      returnUrl: string,
      intent: StripeIntent,
      billing: {
        address?: Address;
        email?: string;
        name?: string;
        phone?: string;
      },
    },
    { rejectWithValue }
  ) => {
    if (stripe === null) {
      return rejectWithValue(Notifications.PAYMENT_STRIPE_ERROR);
    }

    const confirmSetupResponse = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: returnUrl,
        payment_method_data: {
          billing_details: billing,
        }
      },
    });

    if (confirmSetupResponse.error != null) {
      return rejectWithValue(confirmSetupResponse.error.message);
    }

    return intent;
  }, {
    serializeError: serializeBusinessError
  }
);

export const createSubscription = createAsyncThunk(
  'payment/createSubscription',
  async (
    {
      stripe,
      intent,
    }: {
      stripe: Stripe | null,
      intent: StripeIntent,
    },
    { getState, rejectWithValue }
  ) => {
    if (stripe === null) {
      return rejectWithValue(Notifications.PAYMENT_STRIPE_ERROR);
    }

    const input = { paymentMethodId: intent.paymentMethodId };
    const state = getState() as RootState;
    const rawResponse = await post<{ data: { createSubscription: { intentToConfirm: StripeIntent } } }>('/integrations/license-manager-graphql/' + state.org.selectedOrg.id, {
      query: `mutation createSubscription($input: CreateSubscriptionInput!){
        createSubscription(input: $input) {
            intentToConfirm { intentType intentId paymentMethodId paymentMethodType clientSecret status nextActionType nextActionUrl }
          }
        }`,
      variables: { input }
    });
    const response = rawResponse?.data?.createSubscription;
    const intentId = response?.intentToConfirm?.intentId;
    const intentType = response?.intentToConfirm?.intentType;
    const paymentMethodType = response?.intentToConfirm?.paymentMethodType ?? 'none';
    const paymentMethodId = response?.intentToConfirm?.paymentMethodId;

    if (response != null) {
      if (intentType === 'setup') {
        let confirmResponse: SetupIntentResult = null;
        switch (paymentMethodType) {
          case 'card':
            confirmResponse = await stripe.confirmCardSetup(response?.intentToConfirm?.clientSecret, {
              payment_method: paymentMethodId,
            });
            break;
          case 'us_bank_account':
            confirmResponse = await stripe.confirmUsBankAccountSetup(response?.intentToConfirm?.clientSecret, {
              payment_method: paymentMethodId,
            });
            break;
          default:
            return rejectWithValue(`Unsupported payment type: ${paymentMethodType}`);
        }
        if (confirmResponse === null || typeof confirmResponse.error !== 'undefined') {
          return rejectWithValue(confirmResponse.error.message);
        }
      } else if (intentType === 'payment') {
        let confirmResponse: PaymentIntentResult = null;
        switch (paymentMethodType) {
          case 'card':
            confirmResponse = await stripe.confirmCardPayment(response?.intentToConfirm?.clientSecret, {
              payment_method: paymentMethodId,
            });
            break;
          case 'us_bank_account':
            confirmResponse = await stripe.confirmUsBankAccountPayment(response?.intentToConfirm?.clientSecret, {
              payment_method: paymentMethodId,
            });
            break;
          default:
            return rejectWithValue(`Unsupported payment type: ${paymentMethodType}`);
        }
        if (confirmResponse === null || typeof confirmResponse.error !== 'undefined') {
          return rejectWithValue(confirmResponse.error.message);
        }
      }
    } else {
      return rejectWithValue('Could not create subscription.');
    }
    return { intentId, intentType, paymentMethodType, paymentMethodId };
  }, {
    serializeError: serializeBusinessError
  }
);

export const confirmCardSetup = createAsyncThunk(
  'payment/confirmCardSetup',
  async (
    { stripe, clientSecret, paymentMethodId }: { stripe: Stripe | null, clientSecret: string, paymentMethodId: string }
  ) => {
    if (stripe === null) {
      notifyError(Notifications.PAYMENT_STRIPE_ERROR);
      return void 0;
    }

    const confirmCardSetupResponse = await stripe.confirmCardSetup(clientSecret, {
      payment_method: paymentMethodId
    });

    if (confirmCardSetupResponse === null || typeof confirmCardSetupResponse.error !== 'undefined') {
      notifyError(confirmCardSetupResponse.error.message);
      return void 0;
    }
  }, {
    serializeError: serializeBusinessError
  }
);

export const confirmCardPayment = createAsyncThunk(
  'payment/confirmCardPayment',
  async (
    { stripe, clientSecret, paymentMethodId }: { stripe: Stripe | null, clientSecret: string, paymentMethodId: string }
  ) => {
    if (stripe === null) {
      notifyError(Notifications.PAYMENT_STRIPE_ERROR);
      return void 0;
    }

    const confirmCardSetupResponse = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodId
    });

    if (confirmCardSetupResponse === null || typeof confirmCardSetupResponse.error !== 'undefined') {
      notifyError(confirmCardSetupResponse.error.message);
      return void 0;
    }
  }, {
    serializeError: serializeBusinessError
  }
);

export const getVenueSubscription = createAsyncThunk(
  'payment/venueSubscription',
  async (
    _,
    { getState }
  ) => {
    const state = getState() as RootState;
    const orgId = state.org.selectedOrg.id;
    const response = await post<{ data: { venueSubscriptions: VenueSubscription } }>(
      '/integrations/license-manager-graphql/' + orgId,
      {
        query: `
          query($orgId: ID!) {
            venueSubscriptions(rewardsOrgId: $orgId) {
              rewardsFlatFee {
                status
                setupIntent { intentType intentId paymentMethodId paymentMethodType clientSecret status nextActionType nextActionUrl }
                failedCharge {
                  amount
                  paymentIntent { intentType intentId paymentMethodId paymentMethodType clientSecret status nextActionType nextActionUrl }
                  customerFailureMessage
                  cardPayment { brand last4 }
                }
              }
              rewardsSms {
                status
                setupIntent { intentType intentId paymentMethodId paymentMethodType clientSecret status nextActionType nextActionUrl }
                failedCharge {
                  amount
                  paymentIntent { intentType intentId paymentMethodId paymentMethodType clientSecret status nextActionType nextActionUrl }
                  customerFailureMessage
                  cardPayment { brand last4 }
                }
              }
              defaultPaymentMethod { brand last4 }
              setupIntent { intentType intentId paymentMethodId paymentMethodType clientSecret status nextActionType nextActionUrl }
            }
          }`,
        variables: { orgId },
      });
    return response.data.venueSubscriptions;
  }, {
    serializeError: serializeBusinessError
  }
);

export const changePayment = createAsyncThunk(
  'payment/changePayment',
  async (
    { stripe }: { stripe: Stripe | null },
    { getState, rejectWithValue }
  ) => {
    if (stripe === null) {
      return rejectWithValue(Notifications.PAYMENT_STRIPE_ERROR);
    }

    const state = getState() as RootState;
    const orgId = state.org.selectedOrg.id;

    const response = await post<{ data: { changeSubscriptionPaymentMethod: ChangeSubscriptionPaymentMethodResult } }>(
      '/integrations/license-manager-graphql/' + orgId,
      {
        query: `
          mutation changeSubscriptionPaymentMethod($input: ChangeSubscriptionPaymentMethodInput!) {
            changeSubscriptionPaymentMethod(input: $input) { paymentMethodId }
          }`,
        variables: {
          input: {
            rewardsOrgId: orgId,
          },
        },
      });
    return response.data.changeSubscriptionPaymentMethod.paymentMethodId;
  }, {
    serializeError: serializeBusinessError
  }
);

export const updateBillingData = createAsyncThunk(
  'payment/updateBillingData',
  async (
    { orgId, billingName, billingEmail, billingAddress }: { orgId: string, billingName: string, billingEmail: string, billingAddress: BillingAddress },
    { dispatch }
  ) => {

    const input = {
      billingName,
      billingEmail,
      billingAddress
    };

    await post('/integrations/license-manager-graphql/' + orgId, {
      query: `mutation ($input: ChangeSubscriptionBillingDataInput!) {
            changeSubscriptionBillingData(input: $input) {
              rewardsOrgId
            }
          }`,
      variables: { input }
    });
    await dispatch(reloadCurrentOrg(orgId));
  }, {
    serializeError: serializeBusinessError
  }
);

export const createPaymentSetup = createAsyncThunk(
  'payment/createPaymentSetup',
  async (
    {
      stripe,
      email,
      billingPlanId,
      billingAddress,
      billingName
    }: {
      stripe: Stripe | null,
      email: string,
      billingPlanId: string,
      billingAddress: BillingAddress,
      billingName: string,
    },
    { getState, rejectWithValue }
  ) => {
    if (stripe === null) {
      return rejectWithValue(Notifications.PAYMENT_STRIPE_ERROR);
    }

    const input = {
      billingPlanId,
      billingEmail: email,
      billingName,
      billingAddress: {
        city: billingAddress.city,
        streetAddress1: billingAddress.streetAddress1,
        streetAddress2: billingAddress.streetAddress2,
        state: billingAddress.state,
        zipCode: billingAddress.zipCode,
      }
    };
    const state = getState() as RootState;
    const orgId = state.org.selectedOrg.id;
    const response = await post<{ data: { createPaymentSetup: { intentToConfirm: StripeIntent } } }>(
      '/integrations/license-manager-graphql/' + orgId,
      {
        query: `mutation ($input: CreatePaymentSetupInput!){
            createPaymentSetup(input: $input) {
              intentToConfirm { intentType intentId paymentMethodId paymentMethodType clientSecret status nextActionType nextActionUrl }
            }
          }`,
        variables: { input },
      });
    return response.data.createPaymentSetup.intentToConfirm;
  }, {
    serializeError: serializeBusinessError
  }
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchedCases(
      builder, createPayment,
      (state, fetched) => state.createPayment = fetched);
    addFetchedCases(
      builder, confirmCardSetup,
      (state, fetched) => state.confirmCardSetup = fetched);
    addFetchedCases(
      builder, confirmCardPayment,
      (state, fetched) => state.confirmCardPayment = fetched);
    addFetchedCases(
      builder, getVenueSubscription,
      (state, fetched) => state.venueSubscription = fetched);
    addFetchedCases(
      builder, changePayment,
      (state, fetched) => state.changePayment = fetched);
    addFetchedCases(
      builder, updateBillingData,
      (state, fetched) => state.updateBillingData = fetched);
    addFetchedCases(
      builder, createPaymentSetup,
      (state, fetched) => state.paymentSetup = fetched);
    addFetchedCases(
      builder, createSubscription,
      (state, fetched) => state.createSubscription = fetched);
  },
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../../root.reducer';
import { QuickBooksIntegrationStatus } from '../models/QuickBooksIntegrationStatus';
import { getQuickBooksIntegrationStatusQuery } from '../queries/getQuickBooksIntegrationStatusQuery';
import { serializeBusinessError } from '../../../api/common/fetched';

export const getQuickBooksIntegrationStatus = createAsyncThunk<QuickBooksIntegrationStatus, undefined, AppThunkConfig>(
  'quickbooks/getQuickBooksIntegrationStatus',
  async () => await getQuickBooksIntegrationStatusQuery(),
  {
    serializeError: serializeBusinessError,
  });

import { Fetched, createFetched } from '../../api/common/fetched';
import { Announcement, AnnouncementResponse } from './models/announcement';

export interface AnnouncementState {
  announcements: Fetched<Announcement[], string>;
  createAnnouncement: Fetched<AnnouncementResponse, string>;
  saveDraftAnnouncement: Fetched<AnnouncementResponse, string>;
  updateDraftAnnouncement: Fetched<AnnouncementResponse, string>;
  getAnnouncement: Fetched<Announcement, string>;
  deleteAnnouncement: Fetched<string, string>;
  cancelAnnouncement: Fetched<string, string>;
}

export const initialState: AnnouncementState = {
  announcements: createFetched(),
  createAnnouncement: createFetched(),
  saveDraftAnnouncement: createFetched(),
  updateDraftAnnouncement: createFetched(),
  deleteAnnouncement: createFetched(),
  cancelAnnouncement: createFetched(),
  getAnnouncement: createFetched(),
};
